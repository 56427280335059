import React, { useContext, useEffect, useRef } from 'react'

import {
  Typography,
  Box,
  Popper,
  Paper,
  MenuItem,
  MenuList,
  Hidden,
  Grow,
  IconButton,
  Grid,
} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle'
import { ExitIcon } from 'components/icons/personalizedIcons'
import { DROPSHIPPER, SELLER } from 'constants/rolesConst'
import { routesLink } from 'constants/routesConsts'
import authContext from 'contextApi/AuthContext'

const useStyles = makeStyles(styles)

export default function AdminNavbarLinks(props) {

  const history = useHistory()
  const [helpEmail, setHelpEmail] = React.useState(null)

  const [openGeneralSetting, setOpenGeneralSetting] = React.useState(false)
  const [logo, setLogo] = React.useState(props.imagenContext)
  const { userLoggedin, logout } = useContext(authContext)

  useEffect(() => {
    setLogo(props.imagenContext)
  }, [props])

  const handleCloseHelp = () => {
    setHelpEmail(null)
  }

  const [openProfile, setOpenProfile] = React.useState(null)
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }
  const handleCloseProfile = () => {
    setOpenProfile(null)
  }
  const goToConfigurationStore = (commission = '') => {
    let route = ''
    if (commission === '') {
      route = `${routesLink.storeProfile}`
    } else {
      route = `${routesLink.storeCommission}`
    }
    setOpenProfile(null)
    history.push(route)
  }
  const goToUserManagment = () => {
    setOpenProfile(null)
    setOpenGeneralSetting(!openGeneralSetting)
    history.push(routesLink.userManagement)
  }
  const classes = useStyles()

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid className={classes.buttonLink}>
          <div style={{ width: '100%', minWidth: 100 }}>
            <Typography variant="subtitle1" gutterBottom className={classes.userLogin}>
              {userLoggedin.name}
            </Typography>
            <Typography variant="caption" gutterBottom className={classes.userRole}>
              {userLoggedin.role === DROPSHIPPER
                ? 'Admin tienda'
                : userLoggedin.role === SELLER
                ? 'Vendedor'
                : 'Administrador'}
            </Typography>
          </div>
        </Grid>
        <div
          color="transparent"
          aria-label="Perfil"
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Hidden mdDown implementation="css">
            <img src={logo} className={`${classes.photo} ${classes.links}`} alt="..." />
          </Hidden>
        </div>
        <div
          id="rp-token"
          className="rp-container"
          data-param="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcm9kdWN0X2lkIjo4OX0.etIj-VGpUwjGlNfKbBbuKjefixDYTBmh3HOzygR4GeA"
          lang="en"
        />
        <IconButton disableRipple onClick={logout} className={classes.iconStyle}>
          <ExitIcon />
        </IconButton>
      </Grid>

      <Popper
        open={Boolean(openProfile)}
        anchorEl={openProfile}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !openProfile,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true,
          [classes.beforePopper]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="profile-menu-list" style={{ transformOrigin: '0 0 0' }}>
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseProfile}>
                <MenuList role="menu">
                  {userLoggedin.role === DROPSHIPPER && (
                    <>
                      <MenuItem onClick={() => goToConfigurationStore()} className={classes.nested}>
                        Configuración tienda
                      </MenuItem>
                      <MenuItem
                        onClick={() => goToConfigurationStore('commission')}
                        className={classes.nested}
                      >
                        Comisiones
                      </MenuItem>
                    </>
                  )}

                  <MenuItem onClick={logout} className={[classes.nested, classes.colorSession]}>
                    Cerrar sesión
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        open={Boolean(helpEmail)}
        anchorEl={helpEmail}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !helpEmail,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="profile-help" style={{ transformOrigin: '0 0 0' }}>
            <Paper className={classes.anchorPoover}>
              <ClickAwayListener onClickAway={handleCloseHelp}>
                <Box p={2}>
                  <Typography>¿Necesitas ayuda?</Typography>
                  <Typography>Contáctanos al 320889-9858 y en contacto@luegopago.com</Typography>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

AdminNavbarLinks.propTypes = {}
