import { makeStyles } from '@material-ui/core/styles'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

export default makeStyles((theme) => ({
  ...switchStyles,
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '200px ',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputBorder: {
    borderColor: '#FF0000 !important',
    borderWidth: '1px !important',
  },
  button: {
    margin: theme.spacing(1),
  },
  divContent: {
    paddingLeft: 30,
  },
  buttonAction: {
    marginLeft: 10,
  },
  ...buttonStyle,
  chip: {
    marginLeft: 30,
  },
  card: {
    minHeight: 100,
    padding: 20,
  },
  titleStores: {
    marginBottom: 15,
  },
  alert: { position: 'fixed', zIndex: 1, marginBottom: 20 },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListIndividual: {
    width: '100%',
    // height: 450,
  },
  iconDelete: {
    color: 'rgba(244, 67, 54)',
  },

  iconAdd: {
    marginTop: '10%',
  },
  rootList: {
    width: '850px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    // overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  rootListIndividual: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: 'none',
  },
  imageProduct: {
    width: '150px',
    marginLeft: '25%',
  },
  cardList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imageTitle: {
    color: 'white',
  },
  inputTop: {
    marginTop: '20px',
  },
  tableContainer: {
    width: 'auto',
  },
  center: {
    margin: `${theme.spacing(1)}px auto`,
  },
  buttonDetailt: {
    padding: 8,
  },
  margin: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  divTitle: {
    borderBottom: '1px solid #949494',
    paddingBottom: '1px',
    marginBottom: '30px',
    webkitBoxShadow: '0px 2px 5px 1px rgba(0,0,0,0.36)',
    mozBoxShadow: '0px 2px 5px 1px rgba(0,0,0,0.36)',
    boxShadow: '0px 2px 5px -2px rgba(0,0,0,0.36)',
  },
  buttonMargin: {
    marginRight: 8,
  },
  buttonSave: {
    marginTop: 12,
    marginRight: 69,
  },
  btnAdd: {
    width: '190px',
    height: '50px',
    borderRadius: '50px',
    marginLeft: '10px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  typeletra: {
    fontSize: 20,
  },
}))
