import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formActive: {
    fontWeight: 400,
    backgroundColor: theme.palette.secondary.pink,
    color: theme.palette.white.main,
    border: 0,
    height: 40,
    borderRadius: 11,

    '&:hover': {
      backgroundColor: theme.palette.secondary.pink,
    },
  },
  selectActive: {
    color: theme.palette.white.main,
    background: theme.palette.secondary.pink,
  },
  formNotActive: {
    fontWeight: 400,
    height: 40,
  },
}))

export default useStyles
