import React, { useContext, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Divider, Button } from '@material-ui/core'
import Axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { useRouteMatch } from 'react-router-dom'
import * as yup from 'yup'

import useStyles from '../products.styles'

import DetailProduct from './infoProduct/DetailProduct'
import InfoBasic from './infoProduct/InfoBasic'

import BackDrop from 'components/BackDrop'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { DESCRIPTION_LENGHT } from 'constants/productsConstants'
import { API_STORE_INFO, API_PRODUCTS, API_LOCATIONS, API_BASE } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'

const schemaSimpleProduct = yup.object().shape({
  name: yup.string().typeError('Debe de ingresar el nombre').required('Campo requerido'),
  sku: yup
    .string()
    .required('Campo requerido')
    .matches(
      /^[^0\W\u00F1\u00D1].[a-zA-Z0-9\-]{0,13}$/,
      'Formato no válido, este campo no permite caracteres especiales'
    ),
  description: yup.string().max(DESCRIPTION_LENGHT).typeError('Ingresa una descripción correcta'),
  height: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('Debes de ingresar la altura')
    .required('Campo requerido'),
  width: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('Debes de ingresar el ancho')
    .required('Campo requerido'),
  length: yup
    .number()
    .positive('El valor debe ser un número positivo.')
    .typeError('Debes de ingresar el largo')
    .required('Campo requerido'),
  weight: yup
    .string()
    .typeError('Debes de ingresar el peso')
    .required('Campo requerido')
    .matches(
      /^(?:[1-9]\d*(?:\.\d+)?|1(?:\.0+)?)$/,
      'Formato no válido, este campo permite decimales mayores o iguales a 1'
    ),
  stockQuantity: yup.string().required('Campo requerido'),
  warehouse_id_name: yup.string().typeError('Ingrese Bodega de origen').required('Campo requerido'),
})

const schemaIntangibleProduct = yup.object().shape({
  name: yup.string().typeError('Debe de ingresar el nombre').required('Campo requerido'),
  sku: yup
    .string()
    .required('Campo requerido')
    .matches(
      /^[^0\W\u00F1\u00D1].[a-zA-Z0-9\-]{0,13}$/,
      'Formato no válido, este campo no permite caracteres especiales'
    ),
  description: yup.string().max(DESCRIPTION_LENGHT).typeError('Ingresa una descriptión correcta'),
})

const InfoProduct = ({ isShopifyValidation }) => {
  const classes = useStyles()
  const isApproveAdmin = useRouteMatch('/admin/approve-product/:id/:idSeller')
  const isEditAdmin = useRouteMatch('/admin/admin-edit-product/:id/:idSeller')
  const { userLoggedin } = useContext(useAuthContext)
  const {
    product,
    setProduct,
    handleBack,
    handleNext,
    handleCancel,
    setWarehouses,
    isApprove,
    setCountry,
    setCities,
    cities,
    country,
    setSkuRepeated,
  } = useProduct()

  const yupProductSchema = () => {
    if (product.type === 'simple' || product.type === 'variable') {
      return schemaSimpleProduct
    }
    return schemaIntangibleProduct
  }

  const { register, handleSubmit, errors, control, setError, clearError } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',

    resolver: yupResolver(yupProductSchema()),
  })
  const showDialogAlert = useCustomDialogAlert()

  const [tariff, setTariff] = useState([])
  const [loading, setLoading] = useState(false)
  const [warehouse, setWarehouse] = useState([])
  const [valueWarehouse, setValueWarehouse] = useState({})
  const [calculate, setCalculate] = useState(false)
  const [isLoadingCountry, setIsLoadingCountry] = useState(false)
  const [listBrands, setListBrands] = useState([])
  const [valueBrands, setValueBrands] = useState({})
  const [warrantyOption, setWarrantyOption] = useState('Días')

  const defaultProps = {
    options: warehouse,
    getOptionLabel: (option) => {
      return option.title === null ? '' : option.title
    },
  }
  const handleTypePacking = async () => {
    const { dimensions } = product
    if (!product.checkTransport) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Transportadora a cargo del vendedor debe de estar activo`,
        'Cerrar'
      )
      return false
    }
    if (
      !dimensions ||
      !dimensions.height ||
      !dimensions.width ||
      !dimensions.length ||
      !dimensions.weight ||
      product.warehouses[0].warehouseId === 0
    ) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Llena todos los campos para hacer el calculo`,
        'Cerrar'
      )
      return false
    }
    if (
      dimensions.height < 0 ||
      dimensions.width < 0 ||
      dimensions.length < 0 ||
      dimensions.weight < 0
    ) {
      showDialogAlert(true, '¡Advertencia!', `No se permiten valores negativos`, 'Cerrar')
      return false
    }
    const data = {
      length: dimensions.length,
      height: dimensions.height,
      width: dimensions.width,
      weight: dimensions.weight,
      wareHouseId: product.warehouses[0].warehouseId,
    }
    setLoading(true)
    try {
      const response = await Axios({
        url: `${API_PRODUCTS}/ShipmentDetails?Length=${data.length}&Height=${data.height}&Width=${data.width}&Weight=${data.weight}&WareHouseId=${data.wareHouseId}`,
        method: 'GET',
      })
      if (response) {
        setLoading(false)
      }
      if (response.data.header.code === 200) {
        setProduct({ ...product, packageType: response.data.data.packageType })
        setTariff(response.data.data.tarifas)
        setCalculate(true)
        return true
      }
    } catch (error) {
      setLoading(false)
    }
    return false
  }

  const getWarehouse = async () => {
    const { sellerId } = userLoggedin
    let idSeller = '0'
    if (isApproveAdmin) {
      idSeller = isApproveAdmin.params.idSeller
    } else if (isEditAdmin) {
      idSeller = isEditAdmin.params.idSeller
    }
    await Axios.get(
      `${API_STORE_INFO}/Warehouse/${isApproveAdmin || isEditAdmin ? idSeller : sellerId}`
    )
      .then((response) => {
        if (response) {
          setWarehouse(response.data.data)
          setWarehouses(response.data.data) // Save warehouses in product context
        }
      })
      .catch(() => {})
  }
  const getCountry = () => {
    setIsLoadingCountry(true)
    Axios.get(`${API_STORE_INFO}/GetCountryList`)
      .then((res) => {
        setCountry(res.data)
        setIsLoadingCountry(false)
      })
      .catch((err) => {
        setIsLoadingCountry(false)
        console.log(err)
      })
  }
  const getCities = () => {
    setIsLoadingCountry(true)
    Axios.get(`${API_LOCATIONS}/Cities`)
      .then((res) => {
        setCities(res.data)
        setIsLoadingCountry(false)
      })
      .catch((err) => {
        setIsLoadingCountry(false)
        console.log(err)
      })
  }
  const getBrands = async () => {
    setIsLoadingCountry(true)
    Axios.get(`${API_BASE}/Brands/list?PageSize=1000`)
      .then((response) => {
        const brandsIsActive = response.data.data.filter((brand) => brand.isActive)
        setListBrands(brandsIsActive)
        setIsLoadingCountry(false)
      })
      .catch((err) => {
        setIsLoadingCountry(false)
        console.log(err)
      })
  }
  const defaultPropsBrands = {
    options: listBrands,
    getOptionLabel: (option) => {
      return option.name === null ? '' : option.name
    },
  }
  const SetWarranty = () => {
    const diccionary = {
      day: 'Días',
      month: 'Meses',
      year: 'Años',
    }
    const unitWarranty = diccionary[product.warranty.unit]
    if (unitWarranty) setWarrantyOption(unitWarranty)
  }
  useEffect(() => {
    getWarehouse()
    getCountry()
    getCities()
    getBrands()
    SetWarranty()
  }, [])

  useEffect(() => {
    if (product.warehouses && product.warehouses.length > 0 && warehouse && warehouse.length > 0) {
      const objectWrehouse = warehouse.find((wre) => {
        return product.warehouses[0].warehouseId == wre.id
      })
      setValueWarehouse(objectWrehouse)
    }
    setValueBrands(product.brands[0])
  }, [product.warehouse_id, warehouse, product.brands])

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <BackDrop open={isLoadingCountry} />
      <Grid container justify="space-around" alignItems="flex-start">
        <InfoBasic
          register={register}
          errors={errors}
          Controller={Controller}
          control={control}
          setError={setError}
          clearError={clearError}
          setSkuRepeated={setSkuRepeated}
          listBrands={listBrands}
          defaultPropsBrands={defaultPropsBrands}
          valueBrands={valueBrands}
          setValueBrands={setValueBrands}
        />
        <Divider orientation="vertical" flexItem />
        <DetailProduct
          defaultProps={defaultProps}
          handleTypePacking={handleTypePacking}
          loading={loading}
          tariff={tariff}
          calculate={calculate}
          setCalculate={setCalculate}
          valueWarehouse={valueWarehouse}
          setValueWarehouse={setValueWarehouse}
          register={register}
          errors={errors}
          Controller={Controller}
          control={control}
          country={country}
          cities={cities}
          warrantyOption={warrantyOption}
          setWarrantyOption={setWarrantyOption}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.gridButtonsInfoBasic}
      >
        <Button
          className={classes.btnBackgroundPrimary}
          variant="contained"
          color="secondary"
          onClick={handleBack}
        >
          Anterior
        </Button>
        <Button className={classes.btnBackgroundError} onClick={handleCancel}>
          {!isApprove ? 'Cancelar' : 'No hacer nada'}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          className={classes.btnBackgroundPrimary}
          onClick={() => handleNext(isShopifyValidation)}
        >
          Continuar
        </Button>
      </Grid>
    </form>
  )
}

export default InfoProduct
