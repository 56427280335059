/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  TextField,
  FormControlLabel,
  Switch,
  Link,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'
import { format } from 'date-fns'
import MUIDataTable from 'mui-datatables'
import { useHistory } from 'react-router-dom'

import ModalCategoriesImport from '../UploadProducts/ModalCategoriesImport'

import ModalPrice from './Modals/editPrice'
import ModalQuantity from './Modals/editQuantity'
import ModalSalePrice from './Modals/editSalePrice'
import useStyles from './products.styles'

import BackDrop from 'components/BackDrop'
import FiltersBox from 'components/FiltersBox'
import {
  DefaultImage,
  DeleteIcon,
  DownloadIcon,
  PencilIcon,
  UploadIcon,
} from 'components/icons/personalizedIcons'
import ImageTooltip from 'components/ImageTooltip'
import ModalContainer from 'components/ModalContainer'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { routesLink } from 'constants/routesConsts'
import {
  API_PREVIOUS_PRODUCT,
  API_STORE_INFO,
  MICROSERVICES_PRODUCTS,
  API_UPDATE_MASSIVE_PRODUCTS,
  API_DELETE_MASSIVE_PRODUCTS,
} from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import convertToCurrencyCop from 'utils/convertToCurrencyCop'
import getOptionsTables from 'utils/tableMaterial/optionTables'

export default function Products() {
  const { userLoggedin } = useContext(useAuthContext)
  const [filterText, setFilterText] = useState('')
  const { setAllRowsSelectedContext, allRowsSelectedContext, warehouses, setWarehouses } =
    useProduct()
  const ITEM_HEIGHT = 48
  const optionsMenu = ['Editar', 'Restaurar versión', 'Eliminar'] // Se suprimió la opción de 'Duplicar'
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [isLoading, setIsLoading] = useState(false)
  const [typeAction, setTypeAction] = useState(0)
  const [data, setData] = useState([])
  const [isOpenCategories, setIsOpenCategories] = useState(false)
  const [allProducts, setAllProducts] = useState([])
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    prevPage: '',
    nextPage: '',
  })
  const [dataToEdit, setDataToEdit] = useState({})
  const [selectedProductRowData, setSelectedProductRowData] = useState(null)
  const [alertMessage, setAlertMessage] = useState('')
  const [opeModalQuantity, setOpenModalQuantity] = useState(false)
  const [alert, setAlert] = useState(null)
  const [opeModalPrice, setOpenModalPrice] = useState(false)
  const [opeModalSalePrice, setOpenModalSalePrice] = useState(false)
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    action: null,
    personalized: null,
  })
  const [total, setTotal] = useState(0)
  const [filters, setFilters] = useState({})
  const [filtered, setFiltered] = useState({
    typeFilter: '',
    totalFilter: '',
  })

  const showDialogAlert = useCustomDialogAlert()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseModalQuantity = () => {
    setOpenModalQuantity(false)
  }

  const handleCloseModalPrice = () => {
    setOpenModalPrice(false)
  }

  const handleCloseModalSalePrice = () => {
    setOpenModalSalePrice(false)
  }

  const handleCloseDialog = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  const cancelDelete = () => {
    setAlert(null)
  }

  const getModels = async (perPage = 10, page = 0, filter = false) => {
    setAllRowsSelectedContext([])
    setIsLoading(true)

    await axios
      .get(
        `${MICROSERVICES_PRODUCTS}?page=${filter ? pagination.page : page}&pagesize=${
          filter ? pagination.perPage : perPage
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          document.querySelector('#main').scrollTop = 0
          setData(response?.data?.data || [])
          setPagination({
            ...pagination,
            page: page === 0 ? page : page - 1,
            perPage: perPage,
          })
          setAllProducts(response?.data?.data || [])
          setTotal(response.data.pagination.records)
          setIsLoading(false)
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const getFilters = async (visibilty = false) => {
    setIsLoading(true)
    await axios
      .get(`${MICROSERVICES_PRODUCTS}/filters`)
      .then((response) => {
        if (response.status === 200) {
          visibilty
            ? setFiltersApi(
                filtered.typeFilter,
                filtered.totalFilter,
                pagination.perPage,
                pagination.page
              )
            : getModels()
          setFilters(response.data)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const openModalCategories = (event) => {
    event.preventDefault()
    setIsOpenCategories(true)
  }
  const handleClose = async (option, productName, productId) => {
    setAnchorEl(null)
    try {
      switch (option) {
        case 'Editar':
          onClickProduct(productId)
          break
        case 'Eliminar':
          productsToDelete(productId)
          break
        case 'Restaurar versión':
          setDialogAlert({
            open: true,
            title: '¿Desea restaurar la última versión aprobada del producto?',
            message: `¿Está seguro que desea restaurar a la información previa aprobada del producto ${productName} ?`,
            action: async () => {
              try {
                setIsLoading(true)
                handleCloseDialog()
                const respuesta = await axios({
                  url: `${API_PREVIOUS_PRODUCT}/Restore/${productId}`,
                  method: 'PUT',
                })

                if (respuesta.status === 200) {
                  getModels()
                  showDialogAlert(
                    true,
                    '¡Cambio exitoso!',
                    `Se ha restaurado a la última versión del producto ${productName}`,
                    'Cerrar',
                    '',
                    true
                  )
                }
              } catch (error) {
                setIsLoading(false)
              }
            },
          })
          break
        case 'Duplicar':
          history.push(`${routesLink.copyProduct}/${productId}`)
          break
        default:
          break
      }
    } catch (error) {
      console.log(error)
    }
  }

  const setFiltersApi = async (type = null, filterTotal, perPage = 10, page = 0, text = '') => {
    setAllRowsSelectedContext([])
    setIsLoading(true)
    let url = ''
    if (type || filtered.typeFilter) {
      if (text !== '') {
        url = `${MICROSERVICES_PRODUCTS}/?Page=${page}&PageSize=${perPage}&FilterBy=${type}&SearchBy=${text}`
      } else {
        url = `${MICROSERVICES_PRODUCTS}/?Page=${page}&PageSize=${perPage}&FilterBy=${type}`
      }
    } else if (text !== '') {
      url = `${MICROSERVICES_PRODUCTS}/?Page=${page}&PageSize=${perPage}&SearchBy=${text}`
    } else {
      getModels(pagination.perPage, pagination.page)
      return false
    }
    setFiltered({
      typeFilter: type || filtered.typeFilter,
      totalFilter: filterTotal || filtered.totalFilter,
    })

    await axios
      .get(url)
      .then((response) => {
        setIsLoading(false)
        if (response.status === 200) {
          document.querySelector('#main').scrollTop = 0
          setData(response.data.data || [])
          setPagination({
            ...pagination,
            perPage: perPage,
            page: page === 0 ? page : page - 1,
          })
          setTotal(
            response.data.totalCount
              ? response.data.totalCount
              : filterTotal || filtered.totalFilter
          )
        }
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })

    return false
  }

  const productsToDelete = async (productsList) => {
    setIsLoading(true)
    await axios
      .delete(`${MICROSERVICES_PRODUCTS}/${productsList}`)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          getFilters(true)
          setDialogAlert({
            open: false,
          })
          return showDialogAlert(
            true,
            '¡Advertencia!',
            `El producto se eliminó correctamente`,
            'Aceptar',
            '',
            false
          )
        }

        return showDialogAlert(
          true,
          '¡Advertencia!',
          `El producto no se eliminó correctamente`,
          'Aceptar',
          '',
          false
        )
      })
      .catch(() => {
        showDialogAlert(
          true,
          '¡Advertencia!',
          `Hubo un error al eliminar el producto`,
          'Aceptar',
          '',
          false
        )
        setIsLoading(false)
      })
  }
  const alertDelete = (tableMeta) => {
    setDialogAlert({
      open: true,
      title: '',
      message: `¿Está seguro que desea eliminar el producto?`,
      action: () => {
        productsToDelete(tableMeta.rowData[0])
      },
      textButtonConfirm: 'Aceptar',
      textButtonCancel: 'Cancelar',
    })
  }
  const updateStock = async (e, selectedRows, displayData) => {
    setIsLoading(true)
    const productIds = []
    selectedRows.data.forEach((Selected) => {
      const rowSelected = displayData.find((row) => row.dataIndex === Selected.index)
      const dataRow = rowSelected.data[0]
      productIds.push(dataRow)
    })
    const productList = []
    productIds.forEach((id) => {
      const productSelect = data.find((product) => product.id === id)
      if (productSelect) {
        productSelect.stockQuantity = 0
        if (productSelect.type === 'variable') {
          productSelect.variations.map((variation) => {
            variation.stockQuantity = 0
          })
        }
        productList.push(productSelect)
      }
    })
    try {
      const result = await axios({
        url: `${API_UPDATE_MASSIVE_PRODUCTS}`,
        method: 'POST',
        data: productList,
      })
      if (result) {
        setAllRowsSelectedContext([])
        setIsLoading(false)
      }
      if (result.status === 200 || result.status === 201) {
        getFilters(true)
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se actualizó correctamente el stock`,
          'Cerrar',
          '',
          true
        )
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Ha ocurrido un error al actualizar la cantidad, valida que el producto tenga una bodega asignada`,
        'Cerrar'
      )
    }
  }
  const productsToDeleteMassive = async (productList) => {
    setIsLoading(true)
    try {
      const result = await axios({
        url: `${API_DELETE_MASSIVE_PRODUCTS}`,
        method: 'POST',
        data: productList,
      })
      if (result.status === 200 || result.status === 201) {
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `Se eliminaron los productos correctamente`,
          'Cerrar',
          '',
          true
        )
        getFilters(true)
      }
    } catch (error) {
      console.log(error)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `No se eliminaron todo los productos seleccionados`,
        'Cerrar'
      )
    } finally {
      setIsLoading(false)
    }
  }
  const handleChangeSelectAccion = async (e, selectedRows, displayData) => {
    e.preventDefault()
    if (typeAction === 0) {
      return false
    }
    if (typeAction === 3) {
      updateStock(e, selectedRows, displayData)
      return false
    }
    const productList = []
    selectedRows.data.forEach((Selected) => {
      const rowSelected = displayData.find((row) => row.dataIndex === Selected.index)
      const dataRow = rowSelected.data[0]
      productList.push(dataRow)
    })

    if (typeAction === 4) {
      productsToDeleteMassive(productList)
    }
  }

  const getWarehouse = async () => {
    setIsLoading(true)
    const { sellerId } = userLoggedin
    await axios
      .get(`${API_STORE_INFO}/Warehouse/${sellerId}`)
      .then((response) => {
        if (response) {
          setIsLoading(false)
          setWarehouses(response.data.data) // Save warehouses in product context
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const onClickProduct = (productId) => {
    history.push(`${routesLink.editProduct}/${productId}`)
  }

  const onClickAddProduct = () => {
    history.push(`${routesLink.addProduct}`)
  }

  const changePageTable = (paginationPage) => {
    let pageInt = Number(paginationPage) + 1
    if (paginationPage === 1 && paginationPage === pagination.page) {
      pageInt = 1
    }
    if (filtered.typeFilter !== '') {
      setFiltersApi(filtered.typeFilter, filtered.totalFilter, pagination.perPage, pageInt)
    } else {
      getModels(pagination.perPage, pageInt)
    }
  }

  const changePerPageTable = (value) => {
    setPagination({ ...pagination, page: 0, perPage: value })
    if (filtered.typeFilter !== '') {
      setFiltersApi(filtered.typeFilter, filtered.totalFilter, value, pagination.page)
    } else {
      getModels(value, pagination.page)
    }
  }

  const handleChangeVisibility = async (event, productId) => {
    event.preventDefault()
    setIsLoading(true)
    const model = data.find((product) => product.id === productId)
    const updateProduct = { ...model, catalogVisibility: event.target.checked }
    try {
      const result = await axios({
        url: `${MICROSERVICES_PRODUCTS}/${productId}`,
        method: 'PUT',
        data: updateProduct,
      })
      if (result.status === 200 || result.status === 201) {
        getFilters(true)
        showDialogAlert(
          true,
          '¡Cambio exitoso!',
          `El producto se ha de ${
            result.data?.data?.catalogVisibility ? 'visualizar' : 'ocultar'
          } en el catálago`,
          'Cerrar',
          '',
          true
        )
        const newData = data.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              catalogVisibility: result.data?.data?.catalogVisibility || false,
            }
          }
          return product
        })
        setData([...newData])
      }
    } catch (error) {
      setIsLoading(false)
      showDialogAlert(
        true,
        '¡Error!',
        `Hubo un error al actualizar la visibilidad del producto en el catálago`,
        'Cerrar'
      )
    }
    return true
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        download: false,
      },
    },
    {
      name: 'parentId',
      options: {
        display: false,
        download: false,
      },
    },
    {
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[2][0]?.src && tableMeta.rowData[2][0]?.src !== 'string' ? (
            <>
              <ImageTooltip image={tableMeta.rowData[2][0].src} showImage />
            </>
          ) : (
            <DefaultImage />
          )
        },
      },
    },
    {
      name: 'name',
      label: 'NOMBRE PRODUCTO',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>NOMBRE PRODUCTO</th>
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <Link
              href={`${routesLink.editProduct}/${tableMeta.rowData[0]}`}
              color="secondary"
              onClick={(event) => {
                event.preventDefault()
                history.push(`${routesLink.editProduct}/${tableMeta.rowData[0]}`)
              }}
            >
              <Typography variant="subtitle1">{value}</Typography>
            </Link>
          )
        },
      },
    },
    {
      name: 'sku',
      label: 'SKU',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>SKU</th>
        },
        customBodyRender: (value) => {
          return <div style={{ fontFamily: 'Red Hat Display Regular' }}>{value}</div>
        },
      },
    },
    {
      name: 'type',
      label: 'TIPO',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>TIPO</th>
        },
        customBodyRender: (value) => {
          return <div style={{ fontFamily: 'Red Hat Display Regular' }}>{value}</div>
        },
      },
    },

    {
      name: 'price',
      label: 'PRECIO',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>PRECIO</th>
        },
        customBodyRender: (value, tableMeta) => {
          const productType = tableMeta.rowData[5]
          return (
            <>
              {productType === 'variable' ? (
                <span>---</span>
              ) : (
                <div className={classes.adjustTable}>
                  <IconButton
                    className={classes.btnEdit}
                    onClick={() => {
                      setDataToEdit({
                        id: tableMeta.rowData[0],
                        value: value || 0,
                        sku: tableMeta.rowData[3],
                        parentId: tableMeta.rowData[1],
                      })
                      setOpenModalPrice(true)
                    }}
                  >
                    <PencilIcon />
                  </IconButton>
                  <div style={{ fontFamily: 'Red Hat Display Regular' }}>
                    {`$${convertToCurrencyCop(value)}`}
                  </div>
                </div>
              )}
            </>
          )
        },
      },
    },
    {
      name: 'price',
      label: 'PRECIO PROMOCIÓN',
      options: {
        display: false,
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>PRECIO PROMOCIÓN</th>
        },
        customBodyRender: (value, tableMeta) => {
          const productType = tableMeta.rowData[5]
          return (
            <>
              {productType === 'variable' ? (
                <span>--</span>
              ) : (
                <div className={classes.adjustTable}>
                  <IconButton
                    className={classes.btnEdit}
                    onClick={() => {
                      setDataToEdit({
                        id: tableMeta.rowData[0],
                        value: value || '0',
                        parentId: tableMeta.rowData[1],
                        sale_price_date_from: tableMeta.rowData[12],
                        sale_price_date_to: tableMeta.rowData[13],
                      })
                      setOpenModalSalePrice(true)
                    }}
                  >
                    <PencilIcon />
                  </IconButton>

                  {`$${convertToCurrencyCop(value)}`}
                </div>
              )}
            </>
          )
        },
      },
    },
    {
      name: 'stockQuantity',
      label: 'CANTIDAD',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>CANTIDAD</th>
        },
        customBodyRender: (value, tableMeta) => {
          const productType = tableMeta.rowData[5]

          return (
            <>
              {productType === 'variable' ? (
                <span>--</span>
              ) : (
                <div className={classes.adjustTable}>
                  <IconButton
                    className={classes.btnEdit}
                    onClick={() => {
                      setDataToEdit({
                        id: tableMeta.rowData[0],
                        value: value || 0,
                        parentId: tableMeta.rowData[1],
                      })
                      setOpenModalQuantity(true)
                    }}
                  >
                    <PencilIcon />
                  </IconButton>

                  <div style={{ fontFamily: 'Red Hat Display Regular' }}>
                    {convertToCurrencyCop(Number(value)) || 0}
                  </div>
                </div>
              )}
            </>
          )
        },
      },
    },
    {
      name: 'dateCreated',
      label: 'FECHA CREACIÓN',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>FECHA CREACIÓN</th>
        },
        customBodyRender: (value) => {
          return <div style={{ fontFamily: 'Red Hat Display Regular' }}>{value}</div>
        },
      },
    },
    {
      name: 'status',
      label: 'VISIBLE',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>VISIBLE</th>
        },
        customBodyRender: (value, tableMeta) => {
          const productIsVisible = tableMeta.rowData[10]
          const productId = tableMeta.rowData[0]
          const status = tableMeta.rowData[11]
          return (
            <>
              <FormControlLabel
                className={classes.switchVisibility}
                control={
                  <Switch
                    onChange={(target) => handleChangeVisibility(target, productId)}
                    checked={productIsVisible === 'Si'}
                    disabled={status === 'No'}
                    name="isVisible"
                    color="primary"
                    size="small"
                  />
                }
              />
            </>
          )
        },
      },
    },
    {
      name: 'catalogVisibility',
      label: 'Visible',
      options: {
        display: false,
      },
    },
    {
      name: 'enable',
      label: 'Activo',
      options: {
        display: false,
      },
    },
    {
      name: '',
      label: '',
      options: {
        download: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '38ch',
                    boxShadow: '-6px 6px #E8E8E8',
                    fontFamily: 'Red Hat Display Regular',
                  },
                }}
              >
                {optionsMenu.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === 'Pyxis'}
                    onClick={(event) => {
                      event.preventDefault()
                      handleClose(option, selectedProductRowData.name, selectedProductRowData.id)
                    }}
                    style={{ fontFamily: 'Red Hat Display Regular' }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
              <Tooltip title="Editar">
                <IconButton
                  className={classes.btnEdit}
                  onClick={(event) => {
                    event.preventDefault()
                    onClickProduct(tableMeta.rowData[0])
                  }}
                >
                  <PencilIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton
                  className={classes.btnDelete}
                  onClick={(event) => {
                    event.preventDefault()
                    alertDelete(tableMeta)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <IconButton
                onClick={(event) => {
                  setSelectedProductRowData({
                    id: tableMeta.rowData[0],
                    name: tableMeta.rowData[3],
                  })
                  handleClick(event)
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </>
          )
        },
      },
    },
  ]
  const showMenuItemsStatu = () => {
    const optionsAction = [
      <option value="0" style={{ fontFamily: 'Red Hat Display Regular' }}>
        Seleccione
      </option>,
      <option value="4" style={{ fontFamily: 'Red Hat Display Regular' }}>
        Eliminar productos
      </option>,
      <option value="3" style={{ fontFamily: 'Red Hat Display Regular' }}>
        Poner stock en 0
      </option>,
    ]

    const actionsMenu = (
      <>
        <TextField
          id="actions"
          select
          className={classes.actionsButton}
          onChange={(e) => {
            e.preventDefault()
            setTypeAction(parseInt(e.target.value))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          {optionsAction}
        </TextField>
      </>
    )
    return <>{actionsMenu}</>
  }
  const handlerSearch = async (text) => {
    if (text === '') return false
    setFiltersApi(filtered.typeFilter, filtered.totalFilter, pagination.perPage, 1, text)
    setPagination(pagination)
    return true
  }
  const optionsCopy = {
    customToolbarSelect: (selectedRows, displayData) => {
      return (
        <Grid
          container
          className={classes.toolBarSelect}
          style={{ fontFamily: 'Red Hat Display Regular' }}
        >
          {showMenuItemsStatu()}
          <Button
            color="primary"
            variant="contained"
            className={classes.btnApply}
            onClick={(e) => {
              handleChangeSelectAccion(e, selectedRows, displayData)
            }}
          >
            Aplicar acción
          </Button>
        </Grid>
      )
    },
  }

  const options = {
    ...optionsCopy,
    ...getOptionsTables(
      pagination,
      total,
      isLoading,
      setAllRowsSelectedContext,
      setTypeAction,
      allRowsSelectedContext,
      changePageTable,
      changePerPageTable
    ),
  }
  const filterBy = (status) => {
    setData(allProducts.filter((product) => product.status === status))
  }
  const getFilterQuantuity = () => {
    getFilters(true)
  }

  useEffect(() => {
    getFilters().then(() => {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const product = urlParams.get('product')
      if (product) {
        setFilterText(product)
      }
    })
    if (warehouses.length === 0) {
      getWarehouse()
    }
    return () => {
      setAllRowsSelectedContext([])
    }
  }, [])

  return (
    <div id="main">
      {isOpenCategories && (
        <ModalCategoriesImport
          isOpenModalCategories={isOpenCategories}
          setIsOpenModalCategories={setIsOpenCategories}
        />
      )}

      <Grid container justify="space-between">
        <Typography variant="h6" style={{ color: '#6064FF' }}>
          Lista de productos
        </Typography>
        <Grid className={classes.divButtonsHeader}>
          <IconButton
            className={classes.iconButton}
            onClick={() => history.push(routesLink.importProducts)}
          >
            <UploadIcon />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            disabled={isLoading}
            onClick={(event) => openModalCategories(event)}
          >
            <DownloadIcon />
          </IconButton>
          <Button
            onClick={onClickAddProduct}
            className={classes.btnNewProduct}
            variant="contained"
            color="secondary"
            style={{ fontFamily: 'Red Hat Display Bold' }}
          >
            Nuevo producto
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.divContent}>
        <FiltersBox
          initialSearchText={filterText}
          handlerSearch={handlerSearch}
          getModels={getModels}
          setFiltersApi={setFiltersApi}
          filterBy={filterBy}
          filters={filters}
          filtered={filtered}
          setFiltered={setFiltered}
        />
        <MUIDataTable
          data={data.map((item) => {
            return [
              item.id,
              item.parentId,
              item.images,
              item.name,
              item.sku,
              item.type,
              item.regularPrice,
              item.regularPrice,
              item.stockQuantity,
              format(new Date(item?.dateCreated), 'yyyy/MM/dd'),
              item.catalogVisibility ? 'Si' : 'No',
              item.status === 'draft' ? 'No' : 'Si',
            ]
          })}
          columns={columns}
          options={options}
        />

        <BackDrop open={isLoading} />
        <Grid item sm={5} xs={12}>
          {alertMessage && (
            <Alert
              severity=""
              onClose={() => {
                setAlertMessage('')
              }}
              className={classes.alert}
            >
              {alertMessage}
            </Alert>
          )}
        </Grid>
        <ModalContainer
          title="Actualizar cantidad"
          open={opeModalQuantity}
          onClose={handleCloseModalQuantity}
          size="sm"
          disableBackdropClick
        >
          <ModalQuantity
            dataToEdit={dataToEdit}
            onCancel={handleCloseModalQuantity}
            productData={data}
            setData={setData}
            getFilterQuantuity={getFilterQuantuity}
          />
        </ModalContainer>
        <ModalContainer
          title="Precio de producto"
          open={opeModalPrice}
          onClose={handleCloseModalPrice}
          size="sm"
          disableBackdropClick
        >
          <ModalPrice
            dataToEdit={dataToEdit}
            onCancel={handleCloseModalPrice}
            productData={data}
            setData={setData}
          />
        </ModalContainer>
        <ModalContainer
          title="Promoción precio del producto"
          open={opeModalSalePrice}
          onClose={handleCloseModalSalePrice}
          size="sm"
          disableBackdropClick
        >
          <ModalSalePrice
            dataToEdit={dataToEdit}
            onCancel={handleCloseModalSalePrice}
            productData={data}
            setData={setData}
          />
        </ModalContainer>
        <Dialog open={dialogAlert.open} onClose={handleCloseDialog}>
          <DialogTitle id="alert-dialog-title">{dialogAlert.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={classes.titleModal}>
              {dialogAlert.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.groupButton}>
            <Button className={classes.btnBackgroundError} onClick={handleCloseDialog}>
              Cancelar
            </Button>
            <Button
              className={classes.btnBackgroundPrimary}
              variant="contained"
              color="secondary"
              autoFocus
              onClick={() => {
                dialogAlert.action()
              }}
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
        {alert}
      </Grid>
    </div>
  )
}
