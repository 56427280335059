/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import React, { useContext } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import FilterButtons from 'components/FilterButtons'
import SearchPedidos from 'components/SearchPedidos'
import { SUPER_ADMIN, ADMIN_SISTECREDITO } from 'constants/rolesConst'
import useAuthContext from 'contextApi/AuthContext'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 30,
  },
  listActions: {
    width: 151,
  },
  divActionsAndFilters: {
    display: 'flex',
    gap: '15px',
  },
  divStates: {
    display: 'flex',
    columnGap: '10px',
  },
}))

const FiltersOrders = ({
  getModels,
  filterBy,
  filters,
  filterData,
  setResultadosVentanaBusqueda,
}) => {
  const classes = useStyles()
  const { setCurrentFilterOrders } = useContext(useAuthContext)

  const { userLoggedin } = useContext(useAuthContext)

  const showStatusChips = () => {
    const chipAll = (
      <FilterButtons
        key={0}
        label={filters.all ? `${filters.all} Todos` : '0 Todos'}
        onClick={(e) => {
          filterBy(e, false, 'All')
          getModels('All')
          setCurrentFilterOrders('All')
        }}
        active={filterData === 'All'}
      />
    )

    const chipProcessing = (
      <FilterButtons
        key={1}
        label={filters.processing ? `${filters.processing} Nuevos ` : '0 Nuevos'}
        onClick={(e) => {
          filterBy(e, false, 'Procesando')
          getModels('Procesando')
          setCurrentFilterOrders('Procesando')
        }}
        active={filterData === 'Procesando'}
        className={classes.chipButton}
      />
    )

    const chipReadyToSend = (
      <FilterButtons
        key={2}
        label={filters.readyToSend ? `${filters.readyToSend} Por enviar ` : '0 Por enviar'}
        onClick={(e) => {
          filterBy(e, false, 'Listo para enviar')
          getModels('Listo para enviar')
          setCurrentFilterOrders('Listo para enviar')
        }}
        active={filterData === 'Listo para enviar'}
        className={classes.chipButton}
      />
    )

    const chipSent = (
      <FilterButtons
        key={3}
        label={filters.sent ? `${filters.sent} Enviados` : `0 Enviados`}
        onClick={(e) => {
          filterBy(e, false, 'Enviado')
          getModels('Enviado')
          setCurrentFilterOrders('Enviado')
        }}
        active={filterData === 'Enviado'}
      />
    )

    const chipCompleted = (
      <FilterButtons
        key={4}
        label={filters.completed ? `${filters.completed} Completados` : `0 Completados`}
        onClick={(e) => {
          filterBy(e, false, 'Completado')
          getModels('Completado')
          setCurrentFilterOrders('Completado')
        }}
        active={filterData === 'Completado'}
      />
    )

    const chipCancelled = (
      <FilterButtons
        key={5}
        label={filters.cancelled ? `${filters.cancelled} Cancelados` : `0 Cancelados`}
        onClick={(e) => {
          filterBy(e, false, 'Cancelado')
          getModels('Cancelado')
          setCurrentFilterOrders('Cancelado')
        }}
        active={filterData === 'Cancelado'}
      />
    )

    const chipReturns = (
      <FilterButtons
        key={6}
        label={filters.cancelled ? `${filters.refounded} Devoluciones` : `0 Devoluciones`}
        onClick={(e) => {
          filterBy(e, false, 'Devuelto')
          getModels('Devuelto')
          setCurrentFilterOrders('Devuelto')
        }}
        active={filterData === 'Devuelto'}
      />
    )
    const chipDigitalContent = (
      <FilterButtons
        key={6}
        label={
          filters.DigitalContent
            ? `${filters.DigitalContent} Contenido digital`
            : `0 Contenido digital`
        }
        onClick={(e) => {
          getModels('contentDigital', 0, 10)
          setCurrentFilterOrders('contentDigital')
        }}
        active={filterData === 'contentDigital'}
      />
    )

    const chipReviewPending = (
      <FilterButtons
        key={8}
        label={
          filters.PendingReview
            ? `${filters.PendingReview} Pendiente Revisión`
            : `0 Pendiente Revisión`
        }
        onClick={(e) => {
          getModels('PendingReview', 0, 10)
          setCurrentFilterOrders('PendingReview')
        }}
        active={filterData === 'PendingReview'}
      />
    )

    const chipApprovedOrders = (
      <FilterButtons
        key={12}
        label={filters.Approved ? `${filters.Approved} Aprobados` : '0 Aprobados'}
        onClick={(e) => {
          filterBy(e, false, 'Approved')
          getModels('Approved')
          setCurrentFilterOrders('Approved')
        }}
        active={filterData === 'Approved'}
        className={classes.chipButton}
      />
    )

    const chipOrderRejecter = (
      <FilterButtons
        key={13}
        label={filters.Rejected ? `${filters.Rejected} Rechazados` : `0 Rechazados`}
        onClick={(e) => {
          filterBy(e, false, 'Rejected')
          getModels('Rejected')
          setCurrentFilterOrders('Rejected')
        }}
        active={filterData === 'Rejected'}
      />
    )

    const chipAutomaticallyApprovedOrders = (
      <FilterButtons
        key={14}
        label={
          filters.AutoApproved
            ? `${filters.AutoApproved} Aprobados Automáticamente`
            : `0 Aprobados Automáticamente`
        }
        onClick={(e) => {
          getModels('AutoApproved')
          setCurrentFilterOrders('AutoApproved')
        }}
        active={filterData === 'AutoApproved'}
      />
    )

    if (userLoggedin.role === SUPER_ADMIN) {
      const chipFraud = (
        <FilterButtons
          key={7}
          label={
            filters.PossibleFraud ? `${filters.PossibleFraud} Posible Fraude` : `0 Posible Fraude`
          }
          onClick={(e) => {
            filterBy(e, false, 'Fraude')
            getModels('Fraude')
            setCurrentFilterOrders('Fraude')
          }}
          active={filterData === 'Fraude'}
        />
      )
      return [
        chipAll,
        chipProcessing,
        chipReadyToSend,
        chipSent,
        chipCompleted,
        chipCancelled,
        chipFraud,
        chipReturns,
      ]
    }
    if (userLoggedin.role === ADMIN_SISTECREDITO) {
      return [
        chipDigitalContent,
        chipReviewPending,
        chipApprovedOrders,
        chipOrderRejecter,
        chipAutomaticallyApprovedOrders,
      ]
    }
    if (userLoggedin.role !== SUPER_ADMIN) {
      return [
        chipAll,
        chipProcessing,
        chipReadyToSend,
        chipSent,
        chipCompleted,
        chipCancelled,
        chipReturns,
      ]
    }
  }

  return (
    <Grid container justify="space-between" alignItems="flex-end" className={classes.container}>
      <Grid className={classes.divActionsAndFilters}>
        <Grid>
          <Typography variant="subtitle1">Filtrar por:</Typography>
          <Grid className={classes.divStates}>{showStatusChips()}</Grid>
        </Grid>
      </Grid>
      <SearchPedidos setResults={setResultadosVentanaBusqueda} />
    </Grid>
  )
}

export default FiltersOrders
