/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react'

import {
  TextField,
  FormGroup,
  Grid,
  InputLabel,
  TextareaAutosize,
  InputAdornment,
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Axios from 'axios'
import { useRouteMatch } from 'react-router-dom'

import useStyles from '../../../products.styles'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { DESCRIPTION_LENGHT } from 'constants/productsConstants'
import { MICROSERVICES_PRODUCTS } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { useProduct } from 'contextApi/ProductContext'
import { checkSku, onlyNumber, onKeyPressJustNumbers, space } from 'utils/utils'

const InfoBasic = ({ setVariation, variation, fieldValidation, setFieldValidation }) => {
  const isEditAdmin = useRouteMatch('/admin/admin-edit-product/:id/:idSeller')?.isExact
  const isEdit = useRouteMatch('/admin/edit-product/:id')
  const showDialogAlert = useCustomDialogAlert()
  const classes = useStyles()
  const { isApprove, product, setSkuRepeated } = useProduct()
  const { userLoggedin } = useContext(useAuthContext)
  const [isDescriptionError, setIsDescriptionError] = useState(false)
  const onChangeDescription = (e) => {
    setVariation({
      ...variation,
      description: e.target.value,
    })
    if (e.target.value.length > DESCRIPTION_LENGHT) {
      setIsDescriptionError(true)
    } else {
      setIsDescriptionError(false)
    }
  }
  const validateSku = async (sku) => {
    if (product.sku === sku) {
      setSkuRepeated(true)
      showDialogAlert(
        true,
        '¡Advertencia!',
        `El sku ${sku}, no puede ser el mismo que el producto padre.`,
        'Cerrar'
      )
      return false
    }
    await Axios({
      url: `${MICROSERVICES_PRODUCTS}/SkuList`,
      method: 'post',
      data: [`${userLoggedin.name}-${sku}`],
    })
      .then(function (response) {
        if (response.data.length !== 0) {
          setSkuRepeated(true)
          showDialogAlert(
            true,
            '¡Advertencia!',
            `El sku ${sku} ya se encuentra registrado.`,
            'Cerrar'
          )
          setFieldValidation({
            ...fieldValidation,
            sku: true,
          })
        } else {
          setSkuRepeated(false)
        }
      })
      .catch(() => {
        setSkuRepeated(false)
      })
  }
  return (
    <Grid
      container
      justify="space-between"
      alignItems="flex-start"
      className={classes.headerDetail}
    >
      <FormGroup className={classes.formInput}>
        <InputLabel
          error={!!fieldValidation.sku || !!fieldValidation.skuDuplicated}
          className={classes.labelSize}
        >
          SKU
        </InputLabel>
        <TextField
          fullWidth
          id="sku"
          name="sku"
          variant="outlined"
          InputProps={{
            startAdornment: !isEditAdmin && (
              <InputAdornment position="start" variant="outlined">
                {userLoggedin.name.substring(0, 5)}-
              </InputAdornment>
            ),
          }}
          disabled={variation.type === 'edit'}
          onKeyPress={space}
          onBlur={(event) => {
            if (event.target.value !== '') {
              validateSku(event.target.value.trim().toUpperCase())
            }
          }}
          onChange={(event) => {
            if (checkSku(event.target.value)) {
              setFieldValidation({
                ...fieldValidation,
                sku: false,
              })
            } else {
              setFieldValidation({
                ...fieldValidation,
                sku: true,
              })
            }
            setVariation({
              ...variation,
              sku: event.target.value.trim().toUpperCase(),
            })
          }}
          error={!!fieldValidation.sku || !!fieldValidation.skuDuplicated}
          value={variation?.sku
            ?.replace(`${userLoggedin.name.toLowerCase()}-`, '')
            .trim()
            .toUpperCase()}
        />
        {fieldValidation.sku && (
          <span className={classes.spanError}>Este campo es obligatorio</span>
        )}
      </FormGroup>
      <FormGroup className={classes.formInput}>
        <InputLabel className={classes.labelSize} error={!!fieldValidation.stockQuantity}>
          Número de existencias
        </InputLabel>
        <CurrencyTextField
          currencySymbol="#"
          textAlign="left"
          decimalPlaces={0}
          minimumValue={0}
          outputFormat="string"
          disabled={isApprove}
          onChange={(event) => {
            if (onlyNumber(parseInt(event.target.value.split(',').join(''), 10))) {
              if (event.target.value !== '') {
                setFieldValidation({
                  ...fieldValidation,
                  stockQuantity: false,
                })
              } else {
                setFieldValidation({
                  ...fieldValidation,
                  stockQuantity: true,
                })
              }
              setVariation({
                ...variation,
                stockQuantity: parseInt(event.target.value.split(',').join(''), 10),
              })
              return true
            }
            return false
          }}
          value={variation?.stockQuantity}
          variant="outlined"
          id="stockQuantity"
          name="stockQuantity"
          error={!!fieldValidation.stockQuantity}
          onKeyPress={onKeyPressJustNumbers}
        />
        {fieldValidation.stockQuantity && (
          <span className={classes.spanError}>Este campo es obligatorio</span>
        )}
      </FormGroup>
      <FormGroup className={classes.descriptionProduct}>
        <InputLabel>Descripción del producto (Opcional)</InputLabel>
        <TextareaAutosize
          id="description"
          rowsMax={4}
          className={classes.descriptionInfoBasic}
          onChange={(e) => onChangeDescription(e)}
          disabled={isApprove}
          defaultValue={variation?.description?.replace(/<[^>]*>?/g, '')}
        />
        {isDescriptionError && (
          <span className={classes.spanError}>{`Máximo ${DESCRIPTION_LENGHT} caracteres`}</span>
        )}
      </FormGroup>
    </Grid>
  )
}

export default InfoBasic
