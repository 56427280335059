import { useContext, useEffect } from 'react'

import axios from 'axios'
import { useHistory } from 'react-router-dom'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { routeBase } from 'constants/environments.constants'
import { routesLink } from 'constants/routesConsts'
import { PAYMENTS_API_BASE, UPLOAD_FILE_MARKETING, CONCILIATOR_URL } from 'constants/urls'
import useAuthContext from 'contextApi/AuthContext'
import { refreshTokenConciliator } from 'services/conciliator/conciliator.service'
import { refreshTokenMCS } from 'services/marketingcampaingns/marketing.service'
import { refreshToken } from 'services/paymentsServices/payments.service'
import { apiRequestSms } from 'utils/utils'

export default function useAxiosInterceptor() {
  const HEADER_PAYMENTS = routeBase[process.env.REACT_APP_ENVIRONMENT].PAYMENTS
  const headersShopify = routeBase[process.env.REACT_APP_ENVIRONMENT].routeShopify
  const showDialogAlert = useCustomDialogAlert()
  const { getAccessToken, getAccessisShopify, logout, setIsShopify } = useContext(useAuthContext)
  const history = useHistory()

  const setHeaderPayment = () => {
    return {
      Authorization: `Bearer ${localStorage.getItem('luegopago_data_token')}`,
      'x-api-key': HEADER_PAYMENTS.PAYMENTS_API_KEY,
      'Ocp-Apim-Subscription-Key': HEADER_PAYMENTS.PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY,
      'Content-Type': 'application/json; charset=utf-8',
      SCOrigen: HEADER_PAYMENTS.SC_ORIGIN,
      SCAmbiente: HEADER_PAYMENTS.SC_ORIGIN,
      SCLocation: '1.1',
      'x-api-version': '1',
    }
  }
  const setHeaderShopify = (accessToken) => {
    return {
      Authorization: `Bearer ${accessToken}`,
      SCOrigen: headersShopify.SCOrigen,
      country: headersShopify.country,
      'Ocp-Apim-Subscription-Key': headersShopify.subscriptionId,
    }
  }

  const setHeaderMarketingCampaigns = () => {
    return {
      Authorization: `Bearer ${localStorage.getItem('luegopago_marketing_campaigns_token')}`,
    }
  }
  const setHeaderCheckout = () => {
    return {
      Authorization: `Bearer ${localStorage.getItem('luegopago_marketing_campaigns_token')}`,
    }
  }

  useEffect(() => {
    const interceptor = () => {
      const routeShopify = getAccessisShopify() === 'true'
      setIsShopify(routeShopify)
      axios.interceptors.request.use(
        function (config) {
          if (config.url.includes(PAYMENTS_API_BASE)) {
            return {
              ...config,
              headers: setHeaderPayment(),
            }
          }
          if (config.url.includes(UPLOAD_FILE_MARKETING)) {
            return {
              ...config,
              headers: setHeaderMarketingCampaigns(),
            }
          }
          const accessToken = getAccessToken()
          if (accessToken) {
            return {
              ...config,
              headers: setHeaderShopify(accessToken),
            }
          }
          return config
        },
        function (error) {
          return Promise.reject(error)
        }
      )
      axios.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          const originalRequest = error.config
          if (!error.response && !error.status) {
            showDialogAlert(true, '¡Error!', 'Ocurrió un error', 'Cerrar')
            return Promise.reject(error)
          }
          switch (error.response.status) {
            case 400:
              if (error.response.data.errors[0] === 'Input string was not in a correct format.') {
                showDialogAlert(
                  true,
                  '¡ Advertencia !',
                  'Ha ocurrido un error, intentelo mas tarde.',
                  'Cerrar'
                )
                return
              }
              if (
                error.response.data.errors[0] ===
                "Error reading JObject from JsonReader. Current JsonReader item is not an object: StartArray. Path '', line 1, position 1."
              ) {
                showDialogAlert(true, '¡Error!', 'SKU no válido o duplicado.', 'Cerrar')
                return
              }
              if (error.response.data.message) {
                showDialogAlert(true, '¡Error!', error.response.data.message, 'Cerrar')
              } else if (error.response.data.errors) {
                const errorApi = error.response.data.errors
                if (Array.isArray(errorApi)) {
                  if (errorApi[0] === 'No hay configuración para este vendedor.') {
                    errorApi.map((err) =>
                      showDialogAlert(true, '¡ Advertencia !', apiRequestSms(err), 'Cerrar')
                    )
                  } else {
                    errorApi.map((err) =>
                      showDialogAlert(true, '¡ Advertencia !', apiRequestSms(err), 'Cerrar')
                    )
                  }
                } else {
                  Object.keys(errorApi).forEach((key) => {
                    if (Object.prototype.hasOwnProperty.call(errorApi, key)) {
                      const msgErrors = errorApi[key]
                      msgErrors.map((err) =>
                        showDialogAlert(true, '¡ Advertencia !', apiRequestSms(err), 'Cerrar')
                      )
                    }
                  })
                }
              } else {
                showDialogAlert(true, '¡ Error !', '(400) Solicitud no valida', 'Cerrar')
              }
              break
            case 401:
              if (error.config.url.includes(PAYMENTS_API_BASE)) {
                const autorization = 'Authorization'
                return refreshToken().then((res) => {
                  localStorage.setItem('luegopago_data_token', res.data.data.token)
                  axios.defaults.headers.common[autorization] = `Bearer ${localStorage.getItem(
                    'luegopago_data_token'
                  )}`
                  return axios(originalRequest)
                })
              }
              if (error.config.url.includes(UPLOAD_FILE_MARKETING)) {
                const autorization = 'Authorization'
                return refreshTokenMCS().then((res) => {
                  localStorage.setItem('luegopago_marketing_campaigns_token', res.data.data.token)
                  axios.defaults.headers.common[autorization] = `Bearer ${localStorage.getItem(
                    'luegopago_marketing_campaigns_token'
                  )}`
                  return axios(originalRequest)
                })
              }
              if (error.config.url.includes(CONCILIATOR_URL)) {
                console.log('interceptor')
                const autorization = 'Authorization'
                return refreshTokenConciliator().then((res) => {
                  localStorage.setItem('conciliator_token', res.data.data.token)
                  axios.defaults.headers.common[autorization] = `Bearer ${localStorage.getItem(
                    'conciliator_token'
                  )}`
                  return axios(originalRequest)
                })
              }
              logout()
              break
            case 403:
              showDialogAlert(
                true,
                '¡Error!',
                '(403) No estas autorizado para esta acción',
                'Cerrar'
              )
              break
            case 404:
              history.push(routesLink.error404)
              break
            case 405:
              showDialogAlert(true, '¡Error!', '(405) Método no permitido', 'Cerrar')
              break
            case 415:
              showDialogAlert(true, '¡Error!', '(415) Media type no soportado', 'Cerrar')
              break
            case 500:
              history.push(routesLink.error500)
              break
            default:
              break
          }
          return Promise.reject(error)
        }
      )
    }

    interceptor()
    return () => {}
  }, [])
}
