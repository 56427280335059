/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import FilterButtons from 'components/FilterButtons'
import FilterSelects from 'components/FilterSelects'
import SearchBoxFilter from 'components/SearchBoxFilter'
import { routesLink } from 'constants/routesConsts'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 30,
  },
  listActions: {
    width: 151,
  },
  divActionsAndFilters: {
    display: 'flex',
    gap: '15px',
  },
  divStates: {
    display: 'flex',
    columnGap: '10px',
  },
}))

const FiltersStores = ({ getModels }) => {
  const history = useHistory()
  const classes = useStyles()

  const [avalaibleSetTextValueEmpty, setAvalaibleSetTextValueEmpty] = useState(false)
  const [selectedState, setSelectedState] = useState(0)

  const handlerOnClick = (status) => {
    if (status === 3) {
      history.push(`${routesLink.approvals}`)
      return
    }
    setAvalaibleSetTextValueEmpty(true)
    setSelectedState(status)
    getModels('', '01/01/0001 0:00:00', '01/01/0001 0:00:00', status)
  }

  const handlerSearch = (text, startDate, endDate, isFilterWithDates) => {
    setAvalaibleSetTextValueEmpty(false)
    if (isFilterWithDates) {
      getModels(text, startDate, endDate)
    } else {
      getModels(text, '01/01/0001 0:00:00', '01/01/0001 0:00:00')
    }
  }

  return (
    <Grid container justify="space-between" alignItems="flex-end" className={classes.container}>
      <Grid className={classes.divActionsAndFilters}>
        <Grid>
          <Typography variant="subtitle1">Filtrar por:</Typography>
          <Grid className={classes.divStates}>
            <FilterButtons
              label="Todos"
              onClick={() => {
                handlerOnClick(0)
              }}
              active={selectedState === 0}
            />
            <FilterButtons
              label="Activos"
              onClick={() => handlerOnClick(1)}
              active={selectedState === 1}
            />
            <FilterButtons
              label="Inactivos"
              onClick={() => handlerOnClick(2)}
              active={selectedState === 2}
            />
            <FilterButtons
              label="Aprobaciones"
              onClick={() => handlerOnClick(3)}
              active={selectedState === 3}
            />
          </Grid>
        </Grid>
      </Grid>
      <SearchBoxFilter
        handlerSearch={handlerSearch}
        avalaibleSetTextValueEmpty={avalaibleSetTextValueEmpty}
        setAvalaibleSetTextValueEmpty={setAvalaibleSetTextValueEmpty}
      />
    </Grid>
  )
}

export default FiltersStores
