import React, { useState, useEffect } from 'react'

import { Tooltip, Grid, Typography, IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import axios from 'axios'
import { format } from 'date-fns'
import MUIDataTable from 'mui-datatables'
import { useHistory } from 'react-router-dom'

import FiltersStores from './FiltersStores'
import useStyles from './generalConfig.styles'
import ModalActiveStore from './ModalActiveStore'

import BackDrop from 'components/BackDrop'
import { EyeIcon } from 'components/icons/personalizedIcons'
import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { routesLink } from 'constants/routesConsts'
import { API_SELLERS } from 'constants/urls'
import { ExportProducts } from 'utils/functions'
import textLabels from 'utils/MUIDataTableTextLabels'

const Stores = () => {
  const classes = useStyles()
  const history = useHistory()
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    prevPage: '',
    nextPage: '',
    typeFilter: 0, // All: 0, Active : 1, Inactive: 2
  })
  const [optionsMenu, setOptionsMenu] = useState({
    actualState: null,
    options: [],
    idStore: null,
    sellerId: null,
    isUser: null,
  })
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [total, setTotal] = useState(0)
  const showDialogAlert = useCustomDialogAlert()
  const [openModalModel, setOpenModalModel] = useState(false)
  const [sellerToActive, setSellerToActive] = useState({ id: 0, idSeller: 0, isUser: false })

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseModalModel = () => {
    setOpenModalModel(false)
  }

  const handleClose = async () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const openDetailStore = (id) => {
    history.push(`${routesLink.storeDetail}/${id}`)
  }

  const openProducts = (idSeller) => {
    history.push(`${routesLink.productsBySeller}/${idSeller}`)
  }

  const getModels = async (
    searchText = '',
    selectedDateFrom = '01/01/0001 0:00:00',
    selectedDateTo = '01/01/0001 0:00:00',
    typeFilter,
    page,
    perPage
  ) => {
    setIsLoading(true)
    await axios
      .get(
        `${API_SELLERS}?searchText=${searchText}&startDate=${selectedDateFrom}&endDate=${selectedDateTo}&sellerFilterType=${
          typeFilter !== undefined ? typeFilter : pagination.typeFilter
        }&pageSize=${perPage !== undefined ? perPage : pagination.perPage}&pageNumber=${
          page !== undefined ? page : pagination.page
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.data)
          setTotal(response.data.pagination.totalCount)
          setPagination({
            ...pagination,
            page: response.data.pagination.pageNumber - 1,
            perPage: perPage !== undefined ? perPage : response.data.pagination.pageSize,
            typeFilter: typeFilter !== undefined ? typeFilter : pagination.typeFilter,
          })
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const handleClickList = (event, idStore, sellerId, isUser, isActive) => {
    const response = { options: [], idStore: idStore, sellerId: sellerId, isUser: isUser }

    response.options = [
      'Ver productos',
      'Ver tienda',
      !isActive ? 'Activar' : 'Desactivar',
      // 'Exportar productos',
    ]

    setOptionsMenu(response)
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const activeStore = async (id, idSeller = 0, isUser = false, username = '') => {
    try {
      const tempData = {
        id: id,
        userName: username,
      }
      if (idSeller) {
        tempData.sellerId = idSeller
        tempData.isUser = isUser
      }
      setIsLoading(true)
      const result = await axios({
        url: `${API_SELLERS}/ActivateSeller`,
        method: 'post',
        data: tempData,
      })
      if (result) {
        if (result.status === 200) {
          setIsLoading(false)
          handleCloseModalModel()
          getModels('', '01/01/0001 0:00:00', '01/01/0001 0:00:00')
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `La tienda se ha activado correctamente`,
            'Cerrar',
            '',
            true
          )
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const openActiveStore = (id, idSeller, isUser) => {
    if (isUser) {
      showDialogAlert(
        true,
        '¡Advertencia!',
        `Está seguro de volver a activar esta Tienda?`,
        'Confirmar',
        'Cancelar',
        false,
        () => activeStore(id, idSeller, isUser)
      )
    } else {
      setOpenModalModel(true)
      setSellerToActive({ id: id, idSeller: idSeller, isUser: isUser })
    }
  }
  const inactivateStores = async (id, email) => {
    setIsLoading(true)
    try {
      const result = await axios({
        url: `${API_SELLERS}/DeactivateUser?sellerId=${id}&Email=${email}`,
        method: 'get',
      })
      if (result) {
        if (result.status === 200) {
          setIsLoading(false)
          showDialogAlert(
            true,
            '¡Cambio exitoso!',
            `La tienda se ha inactivado correctamente`,
            'cerrar',
            '',
            true
          )
          getModels()
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const confirmInactivateStore = (id, email) => {
    showDialogAlert(
      true,
      '¡Advertencia!',
      `Está seguro de inactivar esta Tienda?, todos los usuarios asociados serán inactivados.`,
      'Confirmar',
      'Cancelar',
      false,
      () => inactivateStores(id, email)
    )
  }
  const selectOption = async (option, idStore, sellerId, isUser, email) => {
    switch (option) {
      case 'Exportar productos':
        await ExportProducts(idStore)
        break
      case 'Ver tienda':
        openDetailStore(idStore)
        break
      case 'Ver productos':
        openProducts(sellerId)
        break
      case 'Activar':
        openActiveStore(idStore, sellerId, isUser)
        break
      case 'Desactivar':
        confirmInactivateStore(sellerId, email)
        break
      default:
        break
    }
  }

  const columns = [
    {
      name: 'id',
      options: { display: false },
    },
    {
      name: 'sellerId',
      options: { display: false },
    },
    {
      name: 'isUser',
      options: { display: false },
    },
    {
      name: 'email',
      label: 'Email de la tienda ',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>EMAIL DE LA TIENDA</th>
          )
        },
      },
    },
    {
      name: 'userName',
      label: 'Nombre de usuario ',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>NOMBRE DE USUARIO</th>
        },
      },
    },
    {
      name: 'name',
      label: 'Nombre de la tienda ',
      options: {
        customHeadLabelRender: () => {
          return (
            <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>NOMBRE DE LA TIENDA</th>
          )
        },
      },
    },
    {
      name: 'createdOn',
      label: 'Fecha de registro',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>FECHA DE REGISTRO</th>
        },
        customBodyRender: (value) => {
          return format(new Date(value), 'yyyy-MM-dd')
        },
      },
    },
    {
      name: 'isActive',
      label: 'Estado ',
      options: {
        customHeadLabelRender: () => {
          return <th style={{ fontFamily: 'Red Hat Display Black', fontSize: 13 }}>ESTADO</th>
        },
        customBodyRender: (value) => {
          return <>{value ? 'Activo' : 'Inactivo'}</>
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Ver">
                <IconButton
                  className={classes.iconEye}
                  onClick={(event) => {
                    event.preventDefault()
                    history.push(`${routesLink.storeDetail}/${tableMeta.rowData[0]}`)
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>

              <IconButton
                onClick={(event) => {
                  handleClickList(
                    event,
                    tableMeta.rowData[0],
                    tableMeta.rowData[1],
                    tableMeta.rowData[2],
                    tableMeta.rowData[7]
                  )
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <div>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      width: '38ch',
                      boxShadow: '-6px 6px #E8E8E8',
                      fontFamily: 'Red Hat Display Regular',
                    },
                  }}
                >
                  {optionsMenu &&
                    optionsMenu?.options.map((option) => (
                      <MenuItem
                        selected={option === 'Pyxis'}
                        onClick={(event) => {
                          event.preventDefault()
                          const storeSelect = tableMeta?.tableData?.find(
                            (store) => store[0] === optionsMenu.idStore
                          )
                          selectOption(
                            option,
                            optionsMenu.idStore,
                            optionsMenu.sellerId,
                            optionsMenu.isUser,
                            storeSelect[3]
                          )
                        }}
                        style={{ fontFamily: 'Red Hat Display Regular' }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            </>
          )
        },
      },
    },
  ]

  useEffect(() => {
    getModels()
  }, [])
  useEffect(() => {
    console.log(data)
  }, [data])

  const changePageTable = (paginationpage) => {
    let pageInt = Number(paginationpage) + 1
    if (paginationpage === 1 && paginationpage === pagination.page) {
      pageInt = 1
    }
    setPagination({
      ...pagination,
      page: pageInt - 1,
      prevPage: pageInt - 1,
      nextPage: pageInt + 1,
    })
    getModels('', '01/01/0001 0:00:00', '01/01/0001 0:00:00', pagination.typeFilter, pageInt)
  }

  const changePerPagetable = (perPage) => {
    setPagination({ ...pagination, perPage: perPage })
    getModels(
      '',
      '01/01/0001 0:00:00',
      '01/01/0001 0:00:00',
      pagination.typeFilter,
      pagination.page,
      perPage
    )
  }

  const setResultadosVentanaBusqueda = (results) => {
    setData(results)
  }

  const options = {
    print: false,
    search: false,
    filter: false,
    sort: false,
    download: false,
    viewColumns: false,
    page: pagination.page,
    serverSide: true,
    responsive: 'scrollMaxHeight',
    selectableRows: 'none',
    textLabels,
    count: total,
    rowsPerPageOptions: [10, 15, 100],
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          changePageTable(tableState.page === 0 ? 1 : tableState.page)
          document.querySelector('#main').scrollTop = 0
          break
        case 'changeRowsPerPage':
          changePerPagetable(tableState.rowsPerPage)
          break
        default:
          break
      }
    },
  }

  return (
    <>
      <Grid container justify="space-between" className={classes.titleStores}>
        <Typography variant="h6">Tiendas</Typography>
      </Grid>

      <Grid container direction="column" className={classes.divContent}>
        <BackDrop open={isLoading} />
        <FiltersStores
          getModels={getModels}
          setResultadosVentanaBusqueda={setResultadosVentanaBusqueda}
        />
        <BackDrop open={isLoading} />
        {openModalModel && (
          <ModalActiveStore
            sellerToActive={sellerToActive}
            activeStore={activeStore}
            getSellers={getModels}
            onCancel={handleCloseModalModel}
          />
        )}
        <MUIDataTable data={data} columns={columns} options={options} />
      </Grid>
    </>
  )
}

export default Stores
