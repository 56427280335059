import React from 'react'

import { Grid, Typography, Button, Paper, Fab, Hidden } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'

import styles from '../generalConfig.styles'

const BoxCertificate = ({ certificateSaved, certificateSelected, error, title, selectFile }) => {
  const classes = styles()
  const downloadCertificate = (certificate) => {
    const link = document.createElement('a')
    link.href = certificate
    document.body.appendChild(link)
    link.click()
  }
  return (
    <>
      <Paper
        className={error && classes.errorCertificate}
        style={{ height: 'auto', paddingLeft: '2%' }}
      >
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={2} md={1}>
            {certificateSelected || certificateSaved ? (
              <Fab
                component="div"
                color={certificateSaved ? 'primary' : 'secondary'}
                size="small"
                disableRipple
              >
                <CheckIcon style={{ color: 'white' }} />
              </Fab>
            ) : null}
          </Grid>

          <Grid item xs={7} md={8}>
            <Typography variant="body1">{title}</Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            xs={3}
            style={{ borderLeftStyle: 'groove' }}
          >
            {certificateSaved && (
              <Button
                onClick={() => downloadCertificate(certificateSaved)}
                className={classes.btnSecondary}
              >
                <GetAppIcon />
                <Hidden smDown>Descargar</Hidden>
              </Button>
            )}
            <Button onClick={selectFile} className={classes.btnSecondary}>
              <PublishIcon />
              <Hidden smDown>Cargar archivo</Hidden>
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {error && (
        <Typography variant="body2" style={{ color: 'red' }}>
          Documento requerido
        </Typography>
      )}
    </>
  )
}

export default BoxCertificate
