import React, { useState } from 'react'

import { Grid, Typography } from '@material-ui/core'

import styles from '../generalConfig.styles'

import BoxCertificate from './BoxCertificate'

import DialogAlert from 'components/DialogAlert'

const Certificates = ({
  dataCertificate,
  setData,
  data,
  errorsCertificates,
  setErrorsCertificates,
}) => {
  const classes = styles()
  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    title: '',
    message: '',
    actionConfirm: null,
    textButtonConfirm: '',
    textButtonCancel: '',
  })

  const uploadRUT = (e) => {
    const certificate = e.target.files
    if (!certificate[0]) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Debes seleccionar un archivo`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }

    if (
      certificate[0].type !== 'application/pdf' &&
      certificate[0].type !== 'image/png' &&
      certificate[0].type !== 'image/jpg' &&
      certificate[0].type !== 'image/jpeg'
    ) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Sólo se permiten archivos .pdf, .png, .jpg, .jpeg`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    setData({
      ...data,
      dataCertificate: {
        ...dataCertificate,
        nameRut: certificate[0].name,
        fileRut: certificate[0],
      },
    })
    setErrorsCertificates({ ...errorsCertificates, fileRut: null })
  }
  const uploadCommercialRegister = (e) => {
    const certificate = e.target.files
    if (!certificate[0]) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Debes seleccionar un archivo`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    if (
      certificate[0].type !== 'application/pdf' &&
      certificate[0].type !== 'image/png' &&
      certificate[0].type !== 'image/jpg' &&
      certificate[0].type !== 'image/jpeg'
    ) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Sólo se permiten archivos .pdf, .png, .jpg, .jpeg`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }

    setData({
      ...data,
      dataCertificate: {
        ...dataCertificate,
        nameCommercialRegister: certificate[0].name,
        fileCommercialRegister: certificate[0],
      },
    })
    setErrorsCertificates({ ...errorsCertificates, fileCommercialRegister: null })
  }
  const uploadLegRepDocumentCopy = (e) => {
    const certificate = e.target.files
    if (!certificate[0]) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Debes seleccionar un archivo`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    if (
      certificate[0].type !== 'application/pdf' &&
      certificate[0].type !== 'image/png' &&
      certificate[0].type !== 'image/jpg' &&
      certificate[0].type !== 'image/jpeg'
    ) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Sólo se permiten archivos .pdf, .png, .jpg, .jpeg`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }

    setData({
      ...data,
      dataCertificate: {
        ...dataCertificate,
        nameLegRepDocumentCopy: certificate[0].name,
        fileLegRepDocumentCopy: certificate[0],
      },
    })
    setErrorsCertificates({ ...errorsCertificates, fileLegRepDocumentCopy: null })
  }
  const uploadFileBankCertificate = (e) => {
    const certificate = e.target.files
    if (!certificate[0]) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Debes seleccionar un archivo`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    if (
      certificate[0].type !== 'application/pdf' &&
      certificate[0].type !== 'image/png' &&
      certificate[0].type !== 'image/jpg' &&
      certificate[0].type !== 'image/jpeg'
    ) {
      setDialogAlert({
        ...dialogAlert,
        open: true,
        title: '¡Advertencia!',
        message: `Sólo se permiten archivos .pdf, .png, .jpg, .jpeg`,
        textButtonCancel: 'Cerrar',
      })
      return true
    }
    setData({
      ...data,
      dataCertificate: {
        ...dataCertificate,
        nameBankCertificate: certificate[0].name,
        fileBankCertificate: certificate[0],
      },
    })
    setErrorsCertificates({ ...errorsCertificates, fileBankCertificate: null })
  }
  const handleCloseDialogAlert = () => {
    setDialogAlert({ ...dialogAlert, open: false })
  }
  return (
    <>
      <DialogAlert
        open={dialogAlert.open}
        title={dialogAlert.title}
        handleClose={handleCloseDialogAlert}
        message={dialogAlert.message}
        textButtonConfirm={dialogAlert.textButtonConfirm}
        actionConfirm={dialogAlert.actionConfirm}
        textButtonCancel={dialogAlert.textButtonCancel}
      />

      <Grid container item md={12}>
        <Typography variant="subtitle1">Adjunta los siguientes documentos</Typography>
      </Grid>

      <Grid
        container
        direction="column"
        justify="flex-start"
        spacing={3}
        style={{ marginTop: '3%', marginBottom: '3%' }}
      >
        <Grid item md={12}>
          <BoxCertificate
            error={errorsCertificates?.fileRut}
            title="RUT (Vigente por 1 año)"
            certificateSaved={dataCertificate.urlRut}
            certificateSelected={dataCertificate.nameRut}
            selectFile={() => document.getElementById('fileRut').click()}
          />

          <input
            accept="application/pdf, image/png, image/jpg, image/jpeg"
            className={classes.input}
            onChange={uploadRUT}
            id="fileRut"
            name="fileRut"
            type="file"
            hidden
          />
        </Grid>
        <>
          <Grid item md={12}>
            <BoxCertificate
              error={errorsCertificates?.fileCommercialRegister}
              title={
                data.personalData.typeOfPersonId === 18
                  ? 'Cámara de comercio(Si aplica, No mayor a 30 días)'
                  : 'Cámara de comercio (No mayor a 30 días)'
              }
              certificateSaved={dataCertificate.urlCommercialRegister}
              certificateSelected={dataCertificate.fileCommercialRegister}
              selectFile={() => document.getElementById('fileCommercialRegister').click()}
            />

            <input
              accept="application/pdf, image/png, image/jpg, image/jpeg"
              className={classes.input}
              onChange={uploadCommercialRegister}
              id="fileCommercialRegister"
              name="fileCommercialRegister"
              type="file"
              hidden
            />
          </Grid>

          <Grid item md={12}>
            <BoxCertificate
              error={errorsCertificates?.fileLegRepDocumentCopy}
              title={
                data.personalData.typeOfPersonId === 18
                  ? 'Copia del documento'
                  : 'Copia documento representante legal'
              }
              certificateSaved={dataCertificate.urlLegRepDocumentCopy}
              certificateSelected={dataCertificate.fileLegRepDocumentCopy}
              selectFile={() => document.getElementById('fileLegRepDocumentCopy').click()}
            />
            <input
              accept="application/pdf, image/png, image/jpg, image/jpeg"
              className={classes.input}
              onChange={uploadLegRepDocumentCopy}
              id="fileLegRepDocumentCopy"
              name="fileLegRepDocumentCopy"
              type="file"
              hidden
            />
          </Grid>
        </>

        <Grid item md={12}>
          <BoxCertificate
            error={errorsCertificates?.fileBankCertificate}
            title="Certificado bancario"
            certificateSaved={dataCertificate.urlBankCertificate}
            certificateSelected={dataCertificate.fileBankCertificate}
            selectFile={() => document.getElementById('fileBankCertificate').click()}
          />
          <input
            accept="application/pdf, image/png, image/jpg, image/jpeg"
            className={classes.input}
            onChange={uploadFileBankCertificate}
            id="fileBankCertificate"
            name="fileBankCertificate"
            type="file"
            hidden
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Certificates
