/* eslint-disable no-nested-ternary */
import React from 'react'

import { FormControl, Select, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './styles'

const FilterSelects = ({ value, onClick, active, itemList, itemSelected, itemNumber, filters }) => {
  const classes = useStyles()
  return (
    <>
      {active ? (
        <FormControl variant="outlined" className={classes.formActive}>
          <Select value={value} className={classes.selectActive}>
            {itemList.map((item) => {
              return (
                <MenuItem
                  value={item}
                  key={item}
                  selected={item === itemSelected}
                  onClick={(e) => {
                    onClick(e, item)
                  }}
                  disabled={item === 'Manifestado' || item === 'No Manifestado' || item === 'Todos'}
                >
                  {item === 'Cancelado'
                    ? filters.cancelled
                    : item === 'Devuelto'
                    ? filters.delivered
                    : null}
{' '}
                  {item === 'Listo para enviar'
                    ? `${itemNumber} Por enviar`
                    : item === 'Completado'
                    ? `${itemNumber} Completados`
                    : item}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl variant="outlined" className={classes.formNotActive}>
          <Select value={value}>
            {itemList.map((item) => (
              <MenuItem
                key={item}
                value={item}
                selected={item === itemSelected}
                onClick={(e) => {
                  onClick(e, item)
                }}
                disabled={item === 'Manifestado' || item === 'No Manifestado'}
              >
                {item === 'Cancelado'
                  ? filters.cancelled
                  : item === 'Devuelto'
                  ? filters.delivered
                  : null}
{' '}
                {item === 'Listo para enviar'
                  ? `${itemNumber} Por enviar`
                  : item === 'Completado'
                  ? `${itemNumber} Completados`
                  : item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}

FilterSelects.defaultProps = {
  value: '',
  active: false,
  onClick: () => null,
  itemList: [],
  itemSelected: '',
  itemNumber: '0',
}

FilterSelects.propTypes = {
  value: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  itemList: PropTypes.array,
  itemSelected: PropTypes.string,
  itemNumber: PropTypes.string,
}

export default FilterSelects
