import React from 'react'

import { Grid, Typography, Paper } from '@material-ui/core'

import styles from '../generalConfig.styles'

import { LegalUserIcon, NaturalUserIcon } from 'components/icons/personalizedIcons'

const TypePerson = ({ setData, data }) => {
  const classes = styles()
  const handleClick = (type) => {
    setData({
      ...data,
      personalData: {
        ...data.personalData,
        typeOfPersonId: type,
        identificationTypeId: type === 19 ? 20 : '',
        sellerTypeId: type,
        fisrtName: '',
        secondName: '',
        lastName: '',
        city: '',
        commercialName: '',
        department: '',
        identification: '',
        storeName: '',
        storePhone: '',
        departmentId: 0,
        cityId: 0,
        person: true,
      },
      dataCertificate: {
        nameRut: '',
        nameCommercialRegister: '',
        nameLegRepDocumentCopy: '',
        nameBankCertificate: '',
        urlRut: '',
        urlCommercialRegister: '',
        urlLegRepDocumentCopy: '',
        urlBankCertificate: '',
        fileRut: '',
        fileCommercialRegister: '',
        fileLegRepDocumentCopy: '',
        fileBankCertificate: '',
      },
    })
  }

  return (
    <div>
      <Grid>
        <Typography variant="subtitle1" className={classes.marginInfo}>
          Selecciona un tipo de persona
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={2}
        style={{ margin: '5% 0 5% 0' }}
      >
        <Grid item xs={12} sm={4}>
          <Paper elevation={2} className={classes.typePerson} onClick={() => handleClick(18)}>
            <NaturalUserIcon size={40} />
            <Typography variant="body1" className={classes.hoverPurple}>
              soy persona
              <br />
              <b>Natural</b>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.typePerson} elevation={2} onClick={() => handleClick(19)}>
            <LegalUserIcon size={40} />
            <Typography variant="body1" className={classes.hoverPurple}>
              Soy persona
              <br />
              <b>Juridica</b>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default TypePerson
