import React, { useState } from 'react'

import { Grid, Button, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import Form from '../components/Form'
import TypePerson from '../components/TypePerson'
import styles from '../generalConfig.styles'

const PersonalData = ({ data, setData, handleNext, cites, setCites, isAdmin }) => {
  // const [cites, setCites] = useState([])
  const classes = styles()
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const onSubmit = (e) => {
    console.log('tabulacion')
    handleNext()
  }
  const backToPersonType = (e) => {
    setData({
      ...data,
      personalData: {
        ...data.personalData,
        typeOfPersonId: null,
      },
    })
  }
  return (
    <div className={classes.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justify="center" alignItems="flex-start" spacing={2}>
          <Grid item md={12} xs={12}>
            {!data?.personalData?.typeOfPersonId ? (
              <TypePerson data={data} setData={setData} errors={errors} register={register} />
            ) : (
              <>
                <Button onClick={backToPersonType} className={classes.btnSecondary}>
                  {`<  Regresar`}
                </Button>
                <Form
                  data={data}
                  setData={setData}
                  errors={errors}
                  register={register}
                  cites={cites}
                  setCites={setCites}
                />
              </>
            )}
          </Grid>
        </Grid>
        {data?.personalData?.typeOfPersonId && (
          <Grid container justify="space-between" alignItems="center" style={{ marginTop: '2%' }}>
            <Grid item xs={5}>
              {!isAdmin && (
                <Button onClick={backToPersonType} className={classes.btnSecondary}>
                  {`<  Regresar`}
                </Button>
              )}
            </Grid>
            <Grid item xs={5}>
              <Button
                color="secondary"
                type="submit"
                fullWidth
                variant="contained"
                className={classes.btnBackgroundPrimary}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </div>
  )
}
export default PersonalData
