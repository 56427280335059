import { makeStyles } from '@material-ui/core/styles'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import switchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

export default makeStyles((theme) => ({
  ...switchStyles,
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '200px ',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputBorder: {
    borderColor: '#FF0000 !important',
    borderWidth: '1px !important',
  },
  gridPriceInputs: {
    maxWidth: 600,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonAction: {
    marginLeft: 10,
  },
  ...buttonStyle,
  chip: {
    paddingTop: 17,
    paddingBottom: 17,
    marginLeft: 30,
    fontWeight: 'bold',
    fontSize: 15,
  },
  avatar: {
    padding: 17,
  },
  card: {
    minHeight: 100,
    padding: 20,
  },
  alert: { position: 'fixed', zIndex: 1, marginBottom: 20 },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListIndividual: {
    width: '100%',
  },
  iconDelete: {
    color: 'rgba(244, 67, 54)',
  },
  rootList: {
    width: '850px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.paper,
  },
  rootListIndividual: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
    marginTop: '4%',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  imageProduct: {
    width: '150px',
    marginLeft: '25%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  cardList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imageTitle: {
    color: 'white',
  },
  inputTop: {
    marginTop: '20px',
    minHeight: 'calc(100vh - 311px)',
  },
  gridAtributtes: {
    width: '100%',
    // marginLeft: '4%',
  },
  tableContainer: {
    width: 'auto',
  },
  rootDiv: {
    width: '100%',
    marginTop: '15px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  typeBotton: {
    marginBottom: 19,
  },
  btnUpload: {
    width: '148px !important',
    height: '50px !important',
  },
  btnNewProduct: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  btnNewProductsmall: {
    width: '111px',
    height: '56px',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  btnCardErrorProcessedImage: {
    minWidth: '50px',
    height: '40px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },

  btnBackgroundPrimary: {
    minWidth: '200px',
    height: '46px',
    borderRadius: '50px',
    border: 'none',
    margin: '20px 0',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  btnBackgroundError: {
    width: '148px',
    height: '46px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
    },
  },
  btnBackgroundErrorPrice: {
    width: '248px',
    height: '56px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  btnBackgroundErrorPriceSmall: {
    width: '111px',
    height: '56px',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  toolBarSelect: {
    fontFamily: 'Red Hat Display Regular',
  },
  spanError: {
    color: theme.palette.error.main,
  },
  gridButtons: {
    marginTop: '5vh',
    gap: '20px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '9vh',
    },
  },
  gridButtonsInfoBasic: {
    marginTop: '10vh',
    gap: '20px',
  },
  gridButtonsImages: {
    marginTop: '5vh',
    gap: '20px',
  },
  gridButtonsAttributesVariations: {
    marginTop: '5vh',
    gap: '20px',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    minHeight: 'calc(100vh - 311px)',
  },
  cardImgTop: {
    height: '60px !important',
    width: '60px !important',
    borderRadius: 'calc(.25rem - 1px)',
  },
  cardImgBottom: {
    width: '100%',
    borderBottomRightRadius: 'calc(.25rem - 1px)',
    borderBottomLeftRadius: 'calc(.25rem - 1px)',
  },
  cardImgOverlay: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    padding: '1.25rem',
  },
  cardImg: {
    width: '100%',
    borderRadius: 'calc(.25rem - 1px)',
  },
  productContaine: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 1280px)': {
      flexDirection: 'row',
    },
  },
  cardImages: {
    position: 'relative',
    width: '100%',
    height: 72,
    margin: '20px 0 20px 0',
    '@media (min-width: 960px)': {
      width: '450px',
    },
    // '@media (min-width: 960px) and (max-width: 1023px)': {
    //   width: '300px',
    // },
    // '@media (min-width: 1024px) and (max-width: 1439px)': {
    //   width: '400px',
    // },
    // '@media (min-width: 1440px)': {
    //   width: '500px',
    // },
  },

  buttonContainer: {
    width: '100%',
    '@media (min-width: 720px)': {
      width: '100px',
    },
  },

  cardImageEmpty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    color: theme.palette.grey.dark,
    fill: theme.palette.grey.dark,
    width: '100%',
    height: 80,
    padding: '30px 20px',
    boxShadow: '0px 3px 6px #0000001a',
    borderRadius: 12,
    gap: '5px',
    margin: '15px 0 15px 0',
    '@media (min-width: 960px)': {
      width: '450px',
    },
    // '@media (min-width: 960px) and (max-width: 1023px)': {
    //   width: '300px',
    // },
    // '@media (min-width: 1024px) and (max-width: 1439px)': {
    //   width: '400px',
    // },
    // '@media (min-width: 1440px)': {
    //   width: '500px',
    // },
  },

  divWarehouseDetailProduct: {
    width: '100%',
  },
  labelVariable: {
    margin: 0,
    fontSize: '0.75rem',
  },
  optionsCard: {
    width: 10,
  },
  buttonOption: {
    minWidth: '40px',
    padding: 0,
  },
  divLoadImages: {
    minWidth: '40px',
    marginBottom: 20,
    marginRight: 0,
    marginLeft: 0,
    '@media (min-width: 720px) and (max-width: 1279px)': {
      marginRight: '10px',
      marginLeft: '40px',
    },
    '@media (min-width: 1280px)': {
      marginRight: '20px',
      marginLeft: '20px',
    },
  },

  titleManageImages: {
    fontWeight: 'bold',
    marginBottom: '15px',
    fontSize: '15px',
  },
  priceTitle: {
    width: '100%',
  },
  descriptionProduct: {
    width: '100%',
    marginBottom: '10px',
  },
  selectBrandsProducts: {
    width: '100%',
    marginTop: '5px',
  },
  gridContainer: {
    marginTop: 10,
    marginBottom: 2,
    with: '40%',
  },
  girdInfoProduct: {
    marginTop: 10,
    marginBottom: 2,
  },
  formGroupPriceStep: {
    width: 290,
  },
  formeditPriceProduct: {
    padding: '15px',
  },
  formGroupPriceStepCheckBox: {
    width: 290,
    display: 'flex',
    flexDirection: 'columns',
    justifyContent: 'center',
  },
  imageLinearGrid: {
    paddingRight: 45,
  },
  descriptionInfoBasic: {
    padding: '14px',
    borderRadius: 6,
    minHeight: 240,
    maxHeight: 240,
    overflow: 'auto !important',
    width: '100%',
    marginTop: 5,
    resize: 'none',
    border: '1px solid black',
  },
  autocompleteDetailProduct: {
    marginTop: 0,
  },
  labelSize: {
    fontSize: 15,
    marginBottom: 8,
    fontFamily: 'Red Hat Display Bold',
  },
  labelCheckBox: {
    fontFamily: 'Red Hat Display Black',
    marginTop: '0.8rem',
  },
  typeValue: {
    marginTop: 20,
  },
  buttonCalculate: {
    width: 246,
  },
  detailSend: {
    font: 'Red Hat Display Black',
    textAlign: 'left',
  },
  formInput: {
    width: '45%',
    padding: 0,
    marginBottom: 10,
  },
  formInputLimit: {
    width: '100%',
    padding: 0,
    marginBottom: 10,
  },
  formInputInfo: {
    width: '24%',
    padding: 0,
    marginBottom: 10,
  },
  headerDetail: {
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
  },
  headerDetailInfo: {
    marginTop: 12,
  },
  buttonAddPart: {
    marginTop: 15,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  adjustTable: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  ProductPartTypo: {
    width: '95%',
    marginBottom: 8,
    fontWeight: '700',
  },

  autocompletePadding: {
    padding: '0 4px !important',
  },
  detailContent: {
    width: '100%',
    marginBottom: 20,
  },
  justifyContent: {
    display: 'contents',
  },
  rootStepIcon: {
    border: '2px solid #ACACAC',
    color: 'white',
    width: '48px',
    height: '47px',
    borderRadius: '50%',
  },
  active: {
    color: `${theme.palette.secondary.blue} !important`,
    border: 'none',
    '& $text': {
      fill: '#ffffff !important',
    },
  },
  text: {
    fill: `${theme.palette.primary.black}`,
  },
  completed: {
    color: `${theme.palette.primary.green} !important`,
    border: 'none',
  },
  labelStepLabel: {
    color: '#1c2d52',
    fontWeight: 'bold !important',
    fontFamily: 'Red Hat Display Medium !important',
    fontSize: '16px',
  },
  activeStepLabel: {
    color: `${theme.palette.secondary.blue} !important`,
    fontWeight: 'bold !important',
    fontFamily: 'Red Hat Display Medium !important',
    fontSize: '16px',
  },
  completedStepLabel: {
    color: `${theme.palette.primary.green} !important`,
    fontWeight: 'bold !important',
    fontFamily: 'Red Hat Display Medium !important',
    fontSize: '16px',
  },
  iconButton: {
    background: theme.palette.secondary.light,
    width: 50,
    height: 50,
    fill: theme.palette.white.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  divButtonsHeader: {
    display: 'flex',
    flexWrap: 'nowrap',
    columnGap: '10px',
  },
  divContent: {
    paddingLeft: 30,
  },
  divTop: {
    marginTop: '2%',
  },
  addAtributtes: {
    marginRight: '2%',
    marginLeft: '2%',
  },
  modalVariationForm: {
    height: '80vh',
  },
  formVariant: {
    height: '100%',
  },
  center: {
    margin: ' 0 auto 0 auto',
  },
  variantContent: {
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  divAtributtesSelection: {
    overflowY: 'auto',
    maxHeight: '418px',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(1, 1fr)',
    rowGap: '10px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      overflowY: 'unset',
    },
  },
  btnApply: {
    width: 200,
    marginLeft: 10,
    marginTop: 5,
    height: 38,
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
  divAtributtesForm: {
    display: 'grid',
    width: '60%',
    gridTemplateColumns: 'repeat(3, 1fr)',
    rowGap: '10px',
  },
  divListVariations: {
    width: '70%',
    marginLeft: 40,
    marginTop: '-1%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '5%',
    },
  },
  divLoadAttributes: {
    minWidth: 270,
    maxWidth: 280,
    marginBottom: 20,
    marginRight: 60,
    marginLeft: 40,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  containerAttrVariations: {
    flexWrap: 'noWrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'Wrap',
    },
  },
  dividerVerticalAttributes: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  imgMiniProduct: {
    width: 44,
    height: 44,
    borderRadius: '50%',
  },
  containerImages: {
    flexDirection: 'column',
    paddingLeft: 0,
    width: '100%',

    '@media (min-width: 960px)': {
      paddingLeft: 15,
    },
  },
  textNewImage: {
    fontSize: 14,
    color: theme.palette.grey.dark,
    textAlign: 'center',
  },
  textNewImageEllipsis: {
    fontSize: 14,
    color: theme.palette.grey.dark,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  InfoProductWrap: {
    flexWrap: 'wrap',
  },
  formInputDivider: {
    width: '100%',
    height: '2px',
    fontWeight: '100',
    margin: '15px 0 27px 0',
  },
  btnDelete: {
    '&:hover': {
      background: theme.palette.error.main,
      fill: theme.palette.white.main,
    },
  },
  btnEdit: {
    '&:hover': {
      background: theme.palette.primary.main,
      fill: theme.palette.white.main,
    },
  },
  switchVisibility: {
    marginLeft: '14px',
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.blue,
    },
    '& .MuiSwitch-sizeSmall .MuiSwitch-thumb': {
      color: theme.palette.secondary.blue,
    },
  },
  divAddUrl: {
    marginTop: 15,
    maxWidth: 'none',
    '@media (min-width: 1280px)': {
      maxWidth: 350,
    },
  },
  divListImages: {
    marginLeft: 0,

    '@media (min-width: 720px)': {
      marginLeft: 40,
    },
  },
  labelInput: {
    marginBottom: 8,
  },
  downloadButton: {
    fill: theme.palette.grey.dark,
    color: theme.palette.white.main,
    backgroundColor: theme.palette.grey.dark,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.white.main,
      fill: theme.palette.primary.main,
    },
  },
  gridButtonsAttributesOptions: {
    columnGap: 20,
    marginTop: '3%',
  },
  inputOption: {
    marginBottom: 8,
  },
  inputName: {
    marginBottom: 8,
  },
  skuMargin: {
    marginLeft: '10px',
  },
  pricePercent: {
    display: 'inline-block',
  },
  titleModal: {
    textAlign: 'center',
    color: '#000000',
  },
  groupButton: {
    display: 'flex',
    justifyContent: 'center',
  },
}))
