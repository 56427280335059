import React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  btnActive: {
    fontWeight: 400,
    backgroundColor: theme.palette.secondary.pink,
    color: theme.palette.white.main,
    border: 0,
    height: 40,
    borderRadius: 5,
    fontFamily: 'Red Hat Display Bold',

    '&:hover': {
      backgroundColor: theme.palette.secondary.pink,
    },
  },
  btnNotActive: {
    fontWeight: 400,
    height: 40,
    border: '1px solid black',
    borderRadius: 5,
    color: theme.palette.black.main,
    fontFamily: 'Red Hat Display Bold',
  },
}))

const FilterButtons = ({ label, onClick, active }) => {
  const classes = useStyles()

  return (
    <>
      {active ? (
        <Button className={classes.btnActive} variant="outlined" onClick={onClick}>
          {label}
        </Button>
      ) : (
        <Button className={classes.btnNotActive} variant="outlined" onClick={onClick}>
          {label}
        </Button>
      )}
    </>
  )
}

FilterButtons.defaultProps = {
  active: false,
  onClick: () => null,
  label: '',
}

FilterButtons.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
}

export default FilterButtons
