/* eslint-disable no-case-declarations */
/* eslint-disable no-fallthrough */
import React, { createContext, useState, useContext } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import { useHistory, useRouteMatch } from 'react-router-dom'

import useCustomDialogAlert from 'components/useCustomDialogAlert'
import { DESCRIPTION_LENGHT } from 'constants/productsConstants'
import { PROMOTIONS } from 'constants/promotions'
import { routesLink } from 'constants/routesConsts'
import { checkSku, onlyDecimalsGreaterThanOne } from 'utils/utils'

const ProductContext = createContext()

export const ProductProvider = ({ children }) => {
  const showDialogAlert = useCustomDialogAlert()
  const history = useHistory()
  const isAdd = useRouteMatch('/admin/add-product')
  const [isBackToCategories, setIsBackToCategories] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [isApprove, setIsApprove] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isModified, setIsModified] = useState(false)
  const [sellerIdSelected, setSellerIdSelected] = useState(null)
  const [warehouses, setWarehouses] = useState([])
  const [typeAction, setTypeAction] = useState('')
  const [cities, setCities] = useState([])
  const [country, setCountry] = useState([])
  const [allRowsSelectedContext, setAllRowsSelectedContext] = useState([])
  const [listProductsShopify, setListProductShopify] = useState([])
  const [skuRepeated, setSkuRepeated] = useState(false)
  const [product, setProduct] = useState({
    id: '0',
    tags: [],
    brands: [],
    shipping: [],
    taxClass: '',
    stockQuantity: 1,
    taxStatus: '',
    stockStatus: '',
    catalogVisibility: true,
    upsellIds: [],
    crossSellIds: [],
    backOrders: '',
    externalUrl: '',
    relatedProductsId: [],
    shortDescription: '',
    shippingClass: '',
    categoriesSearch: '',
    applyWarranty: true,
    averageRating: '',
    purchaseNote: '',
    packageType: '',
    name: '',
    status: 'draft',
    description: '',
    checkTransport: false,
    assumedValue: 0,
    attributes: [],
    variations: [],
    dimensions: '',
    warranty: {
      unit: 'day',
      value: 0,
    },
    promotions: [],
    categories: [],
    sku: '',
    regularPrice: '',
    sale_price: '',
    type: 'simple',
    images: [],
    date_on_sale_from: '',
    date_on_sale_to: '',
    is_iva_discount: false,
    iva_discount_percentage: 0,
    quantityPerShoppingCart: null,
  })
  const resetData = () => {
    setProduct({
      id: '0',
      tags: [],
      brands: [],
      shipping: [],
      taxClass: '',
      stockQuantity: '',
      taxStatus: '',
      stockStatus: '',
      catalogVisibility: true,
      upsellIds: [],
      crossSellIds: [],
      backOrders: '',
      externalUrl: '',
      relatedProductsId: [],
      shortDescription: '',
      shippingClass: '',
      categoriesSearch: '',
      averageRating: '',
      applyWarranty: true,
      purchaseNote: '',
      packageType: '',
      name: '',
      status: 'draft',
      description: '',
      checkTransport: false,
      attributes: [],
      variations: [],
      dimensions: '',
      seller: {},
      warranty: {
        unit: 'day',
        value: 0,
      },
      promotions: [],
      categories: [],
      sku: '',
      regularPrice: '',
      type: 'simple',
      images: [],
      assumedValue: 0,
      quantityPerShoppingCart: null,
    })
  }

  const handleBack = () => {
    setIsBackToCategories(true)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = (isShopify = null) => {
    // Validate required fields
    switch (activeStep) {
      case 1:
        if (isAdd && !checkSku(product.sku)) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Verifique que el sku no tenga caracteres especiales, no comience con cero (0) y sea de ma̝ximo 15 caracteres.`,
            'Cerrar'
          )
          return
        }
        if (product.type === 'virtual' || product.type === 'service') {
          if (!product.name || !product.sku) {
            showDialogAlert(
              true,
              '¡Advertencia!',
              `Valide los campos obligatorios antes de continuar.`,
              'Cerrar'
            )
            return
          }
        } else if (
          !product.name ||
          !product.sku ||
          !product.dimensions ||
          !product.dimensions.height ||
          !product.dimensions.width ||
          !product.dimensions.length ||
          !product.dimensions.weight ||
          product.dimensions.height <= 0 ||
          product.dimensions.width <= 0 ||
          product.dimensions.length <= 0 ||
          product.dimensions.weight <= 0 ||
          !onlyDecimalsGreaterThanOne(product.dimensions.weight)
        ) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Valide los campos obligatorios antes de continuar.`,
            'Cerrar'
          )
          return
        }
        if (skuRepeated) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Valide que el sku no se encuentre registrado`,
            'Cerrar'
          )
          return
        }
        if (product.type === 'virtual' && product.stockQuantity <= 0) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `La cantidad de existencias no puede ser igual o menor a 0.`,
            'Cerrar'
          )
          return
        }

        if (product.type === 'simple') {
          if (
            product.stockQuantity < 0 ||
            product.stockQuantity === '' ||
            product.stockQuantity === undefined ||
            product.warehouses[0].warehouseId === 0 ||
            product.warehouses[0].warehouseId === undefined
          ) {
            showDialogAlert(
              true,
              '¡Advertencia!',
              `Valide los campos obligatorios antes de continuar.`,
              'Cerrar'
            )
            return
          }
        }

        if (
          (product.checkTransport !== undefined &&
            product.checkTransport &&
            product.assumedValue === '') ||
          (product.checkTransport !== undefined &&
            product.checkTransport &&
            product.assumedValue === undefined)
        ) {
          showDialogAlert(true, '¡Advertencia!', `El valor asumido es requerido`, 'Cerrar')
          return
        }

        if (product.description !== '' && product.description.length > DESCRIPTION_LENGHT) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `La descripción no puede superar los ${DESCRIPTION_LENGHT} caracteres`,
            'Cerrar'
          )
          return
        }
        break

      case 2:
        if (product.type !== 'variable') {
          const date = new Date()
          const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
          const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
          const actualDayInitialDate = `${date.getFullYear()}-${month}-${day} 00:00:00`
          const actualDayFinalDate = `${date.getFullYear()}-${month}-${day} 23:59:59`
          const objectPromotion = product.promotions.find((promo) => promo.name === PROMOTIONS)
          if (product.regularPrice === '' || product.regularPrice < 0) {
            showDialogAlert(true, '¡Advertencia!', `Debe ingresar un valor válido`, 'Cerrar')
            return
          }
          if (product.regularPrice === 0 || product.regularPrice === '0') {
            showDialogAlert(
              true,
              '¡Advertencia!',
              `El valor del precio debe ser mayor a 0`,
              'Cerrar'
            )
            return
          }

          if (parseInt(product.regularPrice, 10) <= parseInt(objectPromotion?.value, 10)) {
            showDialogAlert(
              true,
              '¡Advertencia!',
              `El precio de la promoción deber de ser menor al de precio de producto`,
              'Cerrar'
            )
            return
          }

          if (
            (!objectPromotion?.dateOnSaleFrom || !objectPromotion?.dateOnSaleTo) &&
            objectPromotion?.value
          ) {
            showDialogAlert(
              true,
              '¡Advertencia!',
              `Las fechas de promoción son obligatorias`,
              'Cerrar'
            )
            return
          }
          if (
            objectPromotion?.dateOnSaleFrom !== undefined &&
            objectPromotion?.dateOnSaleTo !== undefined &&
            moment(objectPromotion?.dateOnSaleTo) < moment(objectPromotion?.dateOnSaleFrom)
          ) {
            showDialogAlert(
              true,
              '¡Advertencia!',
              `La fecha final de promoción debe ser mayor a la fecha inicial`,
              'Cerrar'
            )
            return
          }
          if (
            objectPromotion?.dateOnSaleFrom !== undefined &&
            moment(objectPromotion?.dateOnSaleFrom) < moment(actualDayInitialDate) &&
            objectPromotion?.value &&
            isAdd
          ) {
            showDialogAlert(
              true,
              '¡Advertencia!',
              `La fecha inicial de promoción debe ser mayor o igual al día de hoy`,
              'Cerrar'
            )
            return
          }
          if (
            objectPromotion?.dateOnSaleTo !== undefined &&
            moment(objectPromotion?.dateOnSaleTo) < moment(actualDayFinalDate) &&
            objectPromotion?.value &&
            isAdd
          ) {
            showDialogAlert(
              true,
              '¡Advertencia!',
              `La fecha final de promoción debe ser mayor o igual al día de hoy`,
              'Cerrar'
            )
            return
          }
          break
        }

        if (product.type === 'variable' && product.images.length === 0) {
          showDialogAlert(
            true,
            '¡Advertencia!',
            `Es necesario que agregue como mínimo una imagen.`,
            'Cerrar'
          )
          return
        }

      default:
        break
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const setTypeActionSelectedType = (value) => {
    setTypeAction(value)
  }
  const handleCancel = () => {
    if (isApprove) {
      history.push(`${routesLink.productsBySeller}/${Number(sellerIdSelected)}`)
    } else if (isAdmin) {
      history.push(`${routesLink.productsBySeller}/${sellerIdSelected}`)
    } else {
      history.push(`${routesLink.products}`)
    }
  }
  return (
    <ProductContext.Provider
      value={{
        product,
        cities,
        country,
        setProduct,
        handleNext,
        handleBack,
        handleCancel,
        activeStep,
        setActiveStep,
        isBackToCategories,
        setIsBackToCategories,
        setTypeActionSelectedType,
        resetData,
        warehouses,
        setWarehouses,
        typeAction,
        allRowsSelectedContext,
        setAllRowsSelectedContext,
        listProductsShopify,
        setListProductShopify,
        setIsAdmin,
        setIsApprove,
        isApprove,
        setIsModified,
        isModified,
        setSellerIdSelected,
        setCities,
        setCountry,
        setSkuRepeated,
        skuRepeated,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

ProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useProduct = () => useContext(ProductContext)
